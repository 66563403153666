import React from 'react';

export const ScrollArea = ({ children, className, style }) => {
    return (
        <div
            className={`overflow-auto ${className}`}
            style={{ maxHeight: '300px', ...style }}
        >
            {children}
        </div>
    );
};
