import React from 'react';
import { Calendar, Clock } from 'lucide-react';
import { Card, CardContent } from './ui/card';
import { Button } from './ui/button';
import { Alert } from './ui/alert';

const TournamentGameCard = ({ 
  game, 
  onPick, 
  selectedTeam, 
  disabled = false 
}) => {
  if (!game) return null;

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      timeZone: 'America/Los_Angeles',
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    });
  };

  const formatTime = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString('en-US', {
      timeZone: 'America/Los_Angeles',
      hour: 'numeric',
      minute: '2-digit',
      hour12: true
    });
  };

  const hasOdds = game.odds && game.odds.length > 0;

  const getBestOdds = (odds, type) => {
    if (!odds || !Array.isArray(odds) || odds.length === 0) return null;

    return odds.reduce((best, current) => {
      if (!best) return current;

      const currentValue = type === 'home' ? current.moneyline_home : current.moneyline_away;
      const bestValue = type === 'home' ? best.moneyline_home : best.moneyline_away;

      if (currentValue == null || bestValue == null) return best;
      return currentValue > bestValue ? current : best;
    }, null);
  };

  const formatOdds = (odds) => {
    if (odds == null) return null;
    if (Math.abs(odds) < 0.01) return "Pick 'Em";
    return odds > 0 ? `+${odds}` : odds.toString();
  };

  const isGameStarted = () => {
    const now = new Date();
    const gameTime = new Date(game.event_date);
    const pacificGameTime = new Date(gameTime.toLocaleString('en-US', {
      timeZone: 'America/Los_Angeles'
    }));
    return now >= pacificGameTime;
  };

  const homeOdds = hasOdds ? getBestOdds(game.odds, 'home') : null;
  const awayOdds = hasOdds ? getBestOdds(game.odds, 'away') : null;

  const renderTeamRow = (team, isHome) => {
    const odds = isHome ? homeOdds : awayOdds;
    const formattedOdds = odds ? formatOdds(isHome ? odds.moneyline_home : odds.moneyline_away) : null;
    const isSelected = selectedTeam === team;

    return (
      <button
        onClick={() => onPick(team)}
        disabled={disabled || isGameStarted()}
        className={`w-full p-4 rounded-lg border transition-all ${
          isSelected
            ? 'border-blue-500 bg-blue-50 text-blue-700'
            : 'border-gray-200 hover:bg-gray-50'
        } ${disabled || isGameStarted() ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}`}
      >
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-3">
            <span className="font-medium text-lg">{team}</span>
            {isSelected && (
              <span className="text-sm font-medium text-blue-600 bg-blue-100 px-2 py-1 rounded">
                Selected
              </span>
            )}
          </div>
          <div className="text-sm">
            {formattedOdds ? (
              <span className={`font-medium ${
                parseFloat(formattedOdds) > 0 ? 'text-green-600' : 'text-red-600'
              }`}>
                {formattedOdds}
              </span>
            ) : (
              <span className="text-gray-400 italic">Odds Not Available</span>
            )}
          </div>
        </div>
      </button>
    );
  };

  return (
    <Card className="bg-white">
      <CardContent className="p-4">
        {/* Date and Time Header */}
        <div className="flex justify-between items-center mb-4">
          <div className="flex items-center space-x-2">
            <Calendar className="h-4 w-4 text-gray-500" />
            <span className="text-sm text-gray-600">{formatDate(game.event_date)}</span>
          </div>
          <div className="flex items-center space-x-2">
            <Clock className="h-4 w-4 text-gray-500" />
            <span className="text-sm text-gray-600">{formatTime(game.event_date)} PT</span>
          </div>
        </div>

        {/* Teams Section */}
        <div className="space-y-3">
          {renderTeamRow(game.home_team, true)}
          {renderTeamRow(game.away_team, false)}
        </div>

        {/* Game Status */}
        <div className="mt-3 text-center">
          {isGameStarted() && (
            <p className="text-sm text-gray-500 mt-2">
              Picks are locked because the game has started.
            </p>
          )}
          {!isGameStarted() && !hasOdds && (
            <p className="text-sm text-gray-500 mt-2">
              Odds are not yet available, but you can still make your pick!
            </p>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default TournamentGameCard;