import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { registerUser } from '../api';
import { User, Mail, Lock, MapPin, ArrowRight, Ticket, CheckCircle } from 'lucide-react';
import TermsOfService from './TermsOfService';
import { Card, CardHeader, CardContent } from './ui/card';
import { Alert, AlertDescription } from './ui/alert';

const RegisterForm = () => {
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [promoCode, setPromoCode] = useState('');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showTOS, setShowTOS] = useState(false);
    const [tosAccepted, setTosAccepted] = useState(false);
    const [verificationSent, setVerificationSent] = useState(false);
    const [promoApplied, setPromoApplied] = useState(false);
    const navigate = useNavigate();

    const handleRegister = async (event) => {
        event.preventDefault();
        
        if (!tosAccepted) {
            setError('You must accept the Terms of Service to register.');
            return;
        }
    
        setLoading(true);
        setError(null);
    
        try {
            const result = await registerUser(email, username, password, zipCode, tosAccepted, promoCode);
            
            console.log('Registration response:', result);
    
            // Store email for verification
            sessionStorage.setItem('pendingVerification', email);
            
            // Set verification sent state
            setVerificationSent(true);
    
            // Handle promo code if applied
            if (result.promo_applied) {
                setPromoApplied(true);
            }
    
            // Clear form fields on success
            setEmail('');
            setUsername('');
            setPassword('');
            setZipCode('');
            setPromoCode('');
    
        } catch (error) {
            console.error('Registration error:', error.response?.data || error);
    
            const errorMessage = error.response?.data?.detail || 
                               error.response?.data?.message || 
                               error.message || 
                               'Registration failed. Please try again.';
    
            setError(errorMessage);
            setVerificationSent(false);
        } finally {
            setLoading(false);
        }
    };

    const handleTOSAccept = () => {
        setTosAccepted(true);
        setShowTOS(false);
        setError(null);
    };

    const handleContact = () => {
        window.location.href = 'mailto:contact@swami.com';
    };

    // Show verification success screen if verification email was sent
    if (verificationSent) {
        return (
            <div className="min-h-screen flex items-center justify-center bg-white py-12 px-4 sm:px-6 lg:px-8">
                <Card className="max-w-md w-full">
                    <CardContent className="pt-8">
                        <div className="text-center pb-8">
                            <CheckCircle className="mx-auto h-12 w-12 text-green-500" />
                            <h2 className="mt-4 text-2xl font-bold text-gray-900">
                                Check Your Email
                            </h2>
                            <p className="mt-2 text-sm text-gray-500">
                                We've sent a verification link to:
                            </p>
                            <p className="mt-1 text-md font-medium text-gray-900">
                                {sessionStorage.getItem('pendingVerification')}
                            </p>
                        </div>

                        <Alert className="bg-blue-50 border-blue-200 mb-6">
                            <AlertDescription className="text-blue-800">
                                <p>Please verify your email to complete your account setup.</p>
                                {promoApplied && (
                                    <p className="mt-2 font-medium">
                                        Your $15 promo credit will be activated after verification!
                                    </p>
                                )}
                                <p className="mt-2 text-sm">
                                    (Don't forget to check your spam folder)
                                </p>
                            </AlertDescription>
                        </Alert>

                        <div className="space-y-4">
                            <button
                                onClick={() => navigate('/verify')}
                                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-black hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black"
                            >
                                Go to verification page
                            </button>
                            
                            <button
                                onClick={() => setVerificationSent(false)}
                                className="w-full flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-900 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black"
                            >
                                Register another account
                            </button>
                        </div>
                    </CardContent>
                </Card>
            </div>
        );
    }

    return (
        <div className="min-h-screen flex items-center justify-center bg-white py-12 px-4 sm:px-6 lg:px-8">
            <button
                onClick={handleContact}
                className="fixed top-4 right-4 p-2 flex items-center gap-2 text-gray-600 hover:text-gray-900 bg-white rounded-full shadow-lg hover:shadow-xl transition-all"
            >
                <Mail className="h-5 w-5" />
                <span className="text-sm font-medium">Contact Support</span>
            </button>

            <Card className="max-w-md w-full">
                <CardHeader>
                    <div className="text-center">
                        <h2 className="text-2xl font-bold text-gray-900">
                            Create a Swami Account
                        </h2>
                        <p className="mt-2 text-sm text-gray-500">
                            Join us to start making your picks
                        </p>
                    </div>
                </CardHeader>
                <CardContent>
                    <form className="space-y-6" onSubmit={handleRegister}>
                        <div className="space-y-4">
                            {/* Email field */}
                            <div>
                                <label htmlFor="email" className="sr-only">Email</label>
                                <div className="relative">
                                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                        <Mail className="h-5 w-5 text-gray-400" />
                                    </div>
                                    <input
                                        id="email"
                                        name="email"
                                        type="email"
                                        required
                                        className="appearance-none block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-black focus:border-black sm:text-sm"
                                        placeholder="Email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </div>
                            </div>
                            
                            {/* Username field */}
                            <div>
                                <label htmlFor="username" className="sr-only">Username</label>
                                <div className="relative">
                                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                        <User className="h-5 w-5 text-gray-400" />
                                    </div>
                                    <input
                                        id="username"
                                        name="username"
                                        type="text"
                                        required
                                        className="appearance-none block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-black focus:border-black sm:text-sm"
                                        placeholder="Username"
                                        value={username}
                                        onChange={(e) => setUsername(e.target.value)}
                                    />
                                </div>
                            </div>

                            {/* Password field */}
                            <div>
                                <label htmlFor="password" className="sr-only">Password</label>
                                <div className="relative">
                                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                        <Lock className="h-5 w-5 text-gray-400" />
                                    </div>
                                    <input
                                        id="password"
                                        name="password"
                                        type="password"
                                        required
                                        className="appearance-none block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-black focus:border-black sm:text-sm"
                                        placeholder="Password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </div>
                            </div>

                            {/* Zip code field */}
                            <div>
                                <label htmlFor="zipcode" className="sr-only">Zip Code</label>
                                <div className="relative">
                                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                        <MapPin className="h-5 w-5 text-gray-400" />
                                    </div>
                                    <input
                                        id="zipcode"
                                        name="zipcode"
                                        type="text"
                                        pattern="[0-9]*"
                                        maxLength="5"
                                        required
                                        className="appearance-none block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-black focus:border-black sm:text-sm"
                                        placeholder="Zip Code"
                                        value={zipCode}
                                        onChange={(e) => setZipCode(e.target.value)}
                                    />
                                </div>
                            </div>

                            {/* Promo code field */}
                            <div>
                                <label htmlFor="promoCode" className="sr-only">Promo Code</label>
                                <div className="relative">
                                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                        <Ticket className="h-5 w-5 text-gray-400" />
                                    </div>
                                    <input
                                        id="promoCode"
                                        name="promoCode"
                                        type="text"
                                        className="appearance-none block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-black focus:border-black sm:text-sm"
                                        placeholder="Promo Code (Optional)"
                                        value={promoCode}
                                        onChange={(e) => setPromoCode(e.target.value.toUpperCase())}
                                    />
                                </div>
                            </div>
                        </div>

                        {/* TOS checkbox */}
                        <div className="flex items-start">
                            <div className="flex items-center h-5">
                                <input
                                    id="tos"
                                    type="checkbox"
                                    checked={tosAccepted}
                                    onChange={(e) => setTosAccepted(e.target.checked)}
                                    className="h-4 w-4 text-black focus:ring-black border-gray-300 rounded"
                                />
                            </div>
                            <div className="ml-3">
                                <label htmlFor="tos" className="text-sm text-gray-700">
                                    I accept the{' '}
                                    <button
                                        type="button"
                                        onClick={() => setShowTOS(true)}
                                        className="text-black hover:text-gray-800 font-medium"
                                    >
                                        Terms of Service
                                    </button>
                                </label>
                            </div>
                        </div>

                        {/* Error message */}
                        {error && (
                            <Alert className="bg-red-50 border-red-200">
                                <AlertDescription className="text-red-800">
                                    {error}
                                </AlertDescription>
                            </Alert>
                        )}

                        {/* Submit button */}
                        <button
                            type="submit"
                            disabled={loading}
                            className={`group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white ${
                                loading ? 'bg-gray-400' : 'bg-gray-900 hover:bg-gray-800'
                            } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black`}
                        >
                            <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                                <ArrowRight className={`h-5 w-5 text-gray-300 group-hover:text-gray-400 ${loading ? 'animate-spin' : ''}`} />
                            </span>
                            {loading ? 'Creating account...' : 'Create account'}
                        </button>

                        {/* Sign in link */}
                        <div className="relative">
                            <div className="absolute inset-0 flex items-center">
                                <div className="w-full border-t border-gray-300"></div>
                            </div>
                            <div className="relative flex justify-center text-sm">
                                <span className="px-2 bg-white text-gray-500">
                                    Already have an account?
                                </span>
                            </div>
                        </div>

                        <button
                            type="button"
                            onClick={() => navigate('/login')}
                            className="w-full flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-900 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black"
                        >
                            Sign in
                        </button>
                    </form>

                    <TermsOfService
                        isOpen={showTOS}
                        onClose={() => setShowTOS(false)}
                        showAccept={!tosAccepted}
                        onAccept={handleTOSAccept}
                    />
                </CardContent>
            </Card>
        </div>
    );
};

export default RegisterForm;