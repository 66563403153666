import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Card, CardHeader, CardContent, CardFooter } from './ui/card';
import { Button } from './ui/button';
import { Loader2, CheckCircle2, XCircle, DollarSign } from 'lucide-react';
import api from '../api';

const PaymentSuccess = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [status, setStatus] = useState('loading');
  const [error, setError] = useState(null);
  const [currentAmount, setCurrentAmount] = useState(0);
  const [finalAmount, setFinalAmount] = useState(0);

  useEffect(() => {
    const verifyPayment = async () => {
      try {
        const paymentId = searchParams.get('paymentId');
        const PayerID = searchParams.get('PayerID');

        console.log('Verifying payment with:', { paymentId, PayerID });

        // Match the API endpoint's expected query parameter format
        const response = await api.post(`/api/payments/verify-payment?paymentId=${paymentId}&PayerID=${PayerID}`);

        console.log('Verification response:', response);

        if (response.data?.success) {
          // Set the final amount from the payment response
          const amount = parseFloat(response.data.amount || 0);
          setFinalAmount(amount);

          // Animate the amount counting up
          let start = 0;
          const steps = 30;
          const increment = amount / steps;
          const stepDuration = 50;

          const timer = setInterval(() => {
            start += increment;
            if (start >= amount) {
              setCurrentAmount(amount);
              clearInterval(timer);
              setTimeout(() => {
                setStatus('success');
                setTimeout(() => {
                  navigate('/dashboard', {
                    state: { 
                      message: 'Payment successful!',
                      paymentAmount: amount
                    }
                  });
                }, 2000);
              }, 500);
            } else {
              setCurrentAmount(start);
            }
          }, stepDuration);
        } else {
          throw new Error(response.data?.detail || 'Payment verification failed');
        }
      } catch (err) {
        console.error('Payment verification error:', err);
        setError(err.response?.data?.detail || err.message);
        setStatus('error');
      }
    };

    verifyPayment();
  }, [searchParams, navigate]);

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50">
      <Card className="w-full max-w-md mx-auto">
        <CardHeader>
          <h2 className="text-2xl font-bold text-center">Payment Status</h2>
        </CardHeader>
        <CardContent className="space-y-4">
          <div className="flex flex-col items-center justify-center p-6">
            {status === 'loading' && (
              <>
                <div className="relative mb-6">
                  <div className="absolute inset-0 flex items-center justify-center">
                    <DollarSign className="h-12 w-12 text-green-500" />
                  </div>
                  <div className="h-24 w-24 rounded-full border-4 border-blue-500 border-t-transparent animate-spin" />
                </div>
                <div className="text-3xl font-bold text-green-600 mb-4">
                  ${currentAmount.toFixed(2)}
                </div>
                <p className="text-gray-600">Processing your payment...</p>
              </>
            )}

            {status === 'success' && (
              <>
                <CheckCircle2 className="h-12 w-12 text-green-500 mb-4" />
                <div className="text-3xl font-bold text-green-600 mb-4">
                  ${finalAmount.toFixed(2)}
                </div>
                <p className="text-gray-600">Payment processed successfully!</p>
                <p className="text-sm text-gray-500 mt-2">
                  Redirecting to dashboard...
                </p>
              </>
            )}

            {status === 'error' && (
              <>
                <XCircle className="h-12 w-12 text-red-500 mb-4" />
                <p className="text-red-600 text-center">
                  {error || 'Payment verification failed'}
                </p>
              </>
            )}
          </div>
        </CardContent>
        {status === 'error' && (
          <CardFooter className="flex justify-center">
            <Button
              onClick={() => navigate('/dashboard')}
              className="w-full max-w-xs"
              variant="secondary"
            >
              Return to Dashboard
            </Button>
          </CardFooter>
        )}
      </Card>
    </div>
  );
};

export default PaymentSuccess;