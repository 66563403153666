import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { MessageSquare, ThumbsUp, ThumbsDown, Lightbulb, Smile, Send, X, Twitter, Facebook, Linkedin, Link, Check, RefreshCw } from 'lucide-react';
import { syncGameStatuses, checkPredictionStatus } from '../api';

const PredictionCard = ({ 
  prediction = null, 
  onCommentSubmit = null, 
  onReactionSubmit = null,
  onRefresh = null
}) => {
  const [isCommenting, setIsCommenting] = useState(false);
  const [newComment, setNewComment] = useState('');
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSyncing, setIsSyncing] = useState(false);
  const [lastSyncTime, setLastSyncTime] = useState(null);
  const [showCopiedMessage, setShowCopiedMessage] = useState(false);
  const [statusDetails, setStatusDetails] = useState(null);

  useEffect(() => {
    const checkGameStatus = async () => {
      if (prediction && 
          prediction.result === 'PENDING' && 
          new Date(prediction.game_time) < new Date()) {
        try {
          const status = await checkPredictionStatus(prediction.id);
          setStatusDetails(status);
          
          if (status.total_inconsistencies > 0) {
            await handleSync();
          }
        } catch (error) {
          setError('Error checking game status');
        }
      }
    };

    checkGameStatus();
  }, [prediction?.id, prediction?.result]);

  if (!prediction) {
    return (
      <div className="border rounded-lg p-4 bg-white">
        <p className="text-gray-500 text-center">Loading prediction...</p>
      </div>
    );
  }

  const showComments = Boolean(onCommentSubmit && onReactionSubmit);

  const getResultBadge = (result) => {
    const badges = {
      'WIN': 'bg-green-100 text-green-800',
      'LOSS': 'bg-red-100 text-red-800',
      'PENDING': 'bg-yellow-100 text-yellow-800',
      'PUSH': 'bg-gray-100 text-gray-800'
    };
    return badges[result] || badges.PENDING;
  };

  const getPointsColor = (result) => {
    const colors = {
      'WIN': 'text-green-600',
      'LOSS': 'text-gray-500',
      'PENDING': 'text-blue-600',
      'PUSH': 'text-gray-500'
    };
    return colors[result] || colors.PENDING;
  };

  const getPointsLabel = (result) => {
    const labels = {
      'WIN': 'Won',
      'LOSS': 'Loss',
      'PENDING': 'Potential',
      'PUSH': 'Push'
    };
    return labels[result] || labels.PENDING;
  };

  const formatOdds = (odds) => {
    if (!odds) return '';
    return odds > 0 ? `+${odds}` : odds.toString();
  };

  const formatGameTime = (dateString) => {
    if (!dateString) return 'Time TBD';
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', {
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
      timeZoneName: 'short'
    }).format(date);
  };

  const formatCreatedAt = (dateString) => {
    if (!dateString) return '';
    const now = new Date();
    const date = new Date(dateString);
    const diffInMinutes = Math.floor((now - date) / 60000);
    
    if (diffInMinutes < 1) return 'just now';
    if (diffInMinutes < 60) return `${diffInMinutes}m ago`;
    
    const diffInHours = Math.floor(diffInMinutes / 60);
    if (diffInHours < 24) return `${diffInHours}h ago`;
    
    const diffInDays = Math.floor(diffInHours / 24);
    if (diffInDays < 7) return `${diffInDays}d ago`;
    
    return new Intl.DateTimeFormat('en-US', {
      month: 'short',
      day: 'numeric'
    }).format(date);
  };

  const handleSync = async () => {
    if (isSyncing) return;
    
    setIsSyncing(true);
    setError(null);
    try {
      await syncGameStatuses();
      setLastSyncTime(new Date());
      
      // Add delay before refresh to allow backend to process
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      if (onRefresh) {
        await onRefresh();
      }
    } catch (err) {
      setError('Failed to sync game status. Please try again.');
    } finally {
      setIsSyncing(false);
    }
  };

  const handleCommentSubmit = async (e) => {
    e.preventDefault();
    if (!newComment.trim() || !onCommentSubmit) return;

    setIsSubmitting(true);
    setError(null);

    try {
      await onCommentSubmit(prediction.id, newComment);
      setNewComment('');
      setIsCommenting(false);
    } catch (err) {
      setError('Failed to post comment. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleReaction = async (commentId, reactionType) => {
    if (!onReactionSubmit) return;

    try {
      setError(null);
      await onReactionSubmit(prediction.id, commentId, reactionType);
    } catch (err) {
      setError('Failed to add reaction. Please try again.');
    }
  };

  const getSharableContent = () => {
    const result = prediction.result ? ` (${prediction.result})` : '';
    const possiblePoints = prediction.possible_points ? ` - ${prediction.possible_points} points possible` : '';
    return `Check out my prediction for ${prediction.game}: ${prediction.pick}${result}${possiblePoints}`;
  };

  const handleShare = (platform) => {
    const text = encodeURIComponent(getSharableContent());
    const url = encodeURIComponent(window.location.href);
    
    const shareUrls = {
      twitter: `https://twitter.com/intent/tweet?text=${text}&url=${url}`,
      facebook: `https://www.facebook.com/sharer/sharer.php?u=${url}`,
      linkedin: `https://www.linkedin.com/sharing/share-offsite/?url=${url}`
    };

    if (platform === 'copy') {
      navigator.clipboard.writeText(`${getSharableContent()} ${window.location.href}`).then(() => {
        setShowCopiedMessage(true);
        setTimeout(() => setShowCopiedMessage(false), 2000);
      });
    } else {
      window.open(shareUrls[platform], '_blank', 'width=600,height=400');
    }
  };

  const SocialButton = ({ platform, icon: Icon, label }) => (
    <button
      onClick={() => handleShare(platform)}
      className="inline-flex items-center gap-1 px-2 py-1 text-xs rounded-full transition-colors hover:bg-gray-100"
      title={label}
    >
      <Icon className="h-4 w-4" />
    </button>
  );

  const ReactionButton = ({ type, count = 0, isActive = false, onClick }) => {
    const icons = {
      'LIKE': ThumbsUp,
      'DISLIKE': ThumbsDown,
      'INSIGHTFUL': Lightbulb,
      'FUNNY': Smile
    };
    const colors = {
      'LIKE': 'text-blue-600 bg-blue-50',
      'DISLIKE': 'text-red-600 bg-red-50',
      'INSIGHTFUL': 'text-purple-600 bg-purple-50',
      'FUNNY': 'text-yellow-600 bg-yellow-50'
    };
    
    const Icon = icons[type];
    
    return (
      <button
        onClick={onClick}
        className={`inline-flex items-center gap-1 px-2 py-1 text-xs rounded-full transition-colors ${
          isActive 
            ? colors[type]
            : 'text-gray-500 hover:bg-gray-100'
        }`}
      >
        <Icon className="h-3 w-3" />
        <span>{count}</span>
      </button>
    );
  };

  return (
    <div className="border rounded-lg p-4 hover:shadow-md transition-shadow bg-white space-y-4">
      {/* Prediction Header */}
      <div className="flex justify-between items-start gap-4">
        <div className="flex-grow">
          <div className="flex items-center gap-2 mb-1">
            <h3 className="font-semibold text-lg">{prediction.game || 'Untitled Game'}</h3>
            <span className={`px-2 py-0.5 text-xs font-medium rounded-full ${getResultBadge(prediction.result)}`}>
              {prediction.result || 'PENDING'}
            </span>
            
            <div className="flex items-center gap-1 ml-auto">
              {/* Sync Button */}
              <button
                onClick={handleSync}
                disabled={isSyncing}
                className={`inline-flex items-center gap-1 px-2 py-1 text-xs rounded-full transition-colors hover:bg-gray-100 ${
                  isSyncing ? 'opacity-50 cursor-not-allowed' : ''
                }`}
                title={lastSyncTime ? `Last synced: ${formatCreatedAt(lastSyncTime)}` : 'Sync game status'}
              >
                <RefreshCw className={`h-4 w-4 ${isSyncing ? 'animate-spin' : ''}`} />
              </button>

              {/* Social Share Buttons */}
              <SocialButton 
                platform="twitter" 
                icon={Twitter} 
                label="Share on Twitter"
              />
              <SocialButton 
                platform="facebook" 
                icon={Facebook}
                label="Share on Facebook"
              />
              <SocialButton 
                platform="linkedin" 
                icon={Linkedin}
                label="Share on LinkedIn"
              />
              <button
                onClick={() => handleShare('copy')}
                className="inline-flex items-center gap-1 px-2 py-1 text-xs rounded-full transition-colors hover:bg-gray-100"
                title={showCopiedMessage ? "Copied!" : "Copy Link"}
              >
                {showCopiedMessage ? (
                  <Check className="h-4 w-4 text-green-600" />
                ) : (
                  <Link className="h-4 w-4" />
                )}
              </button>
            </div>
          </div>
          
          <p className="text-gray-600 mb-2">
            Pick: <span className="font-medium">{prediction.pick || 'N/A'}</span>
          </p>
          
          <div className="flex flex-wrap items-center gap-x-4 gap-y-2 text-sm text-gray-500">
            <span className="flex items-center">
              📅 {formatGameTime(prediction.game_time)}
            </span>
            <span className="flex items-center">
              🎲 {prediction.bet_type || 'N/A'}
            </span>
            {prediction.odds && (
              <span className="flex items-center">
                💰 {formatOdds(prediction.odds)}
              </span>
            )}
            <span className="flex items-center">
              📊 {prediction.units || 0}
            </span>
          </div>

          {prediction.reasoning && (
            <p className="mt-3 text-gray-700 text-sm">{prediction.reasoning}</p>
          )}
        </div>

        <div className="text-right flex flex-col items-end">
          <div className={`mb-2 ${getPointsColor(prediction.result)}`}>
            <p className="text-2xl font-bold">{prediction.potential_points || 0}</p>
            <p className="text-sm">{getPointsLabel(prediction.result)}</p>
          </div>
          {prediction.earned_points > 0 && (
            <div className="text-green-600">
              <p className="text-lg font-bold">{prediction.earned_points.toFixed(1)}</p>
              <p className="text-xs">Points</p>
            </div>
          )}
        </div>
      </div>

      {/* Error Message */}
      {error && (
        <div className="p-3 bg-red-50 border border-red-200 text-red-600 rounded-md text-sm flex justify-between items-center">
          <span>{error}</span>
          <button 
            onClick={() => setError(null)}
            className="text-red-400 hover:text-red-600"
          >
            <X className="h-4 w-4" />
          </button>
        </div>
      )}

      {/* Comments Section */}
      {showComments && (
        <div className="space-y-4">
          {/* Comment Input */}
          {!isCommenting ? (
            <button
              onClick={() => setIsCommenting(true)}
              className="inline-flex items-center gap-2 text-sm text-gray-600 hover:text-gray-900"
            >
              <MessageSquare className="h-4 w-4" />
              {(prediction.comments?.length || 0) > 0 
                ? `${prediction.comments.length} Comment${prediction.comments.length !== 1 ? 's' : ''}`
                : 'Add Comment'
              }
            </button>
          ) : (
            <form onSubmit={handleCommentSubmit} className="space-y-2">
              <textarea
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}
                placeholder="Write your comment..."
                className="w-full px-3 py-2 text-sm border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                rows="3"
              />
              <div className="flex justify-end gap-2">
                <button
                  type="button"
                  onClick={() => {
                    setIsCommenting(false);
                    setNewComment('');
                  }}
                  className="inline-flex items-center gap-1 px-3 py-1 text-sm text-gray-600 hover:text-gray-900"
                >
                  <X className="h-4 w-4" />
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={isSubmitting || !newComment.trim()}
                  className="inline-flex items-center gap-2 px-3 py-1 text-sm text-white bg-blue-600 rounded-md hover:bg-blue-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {isSubmitting ? (
                    <div className="animate-spin rounded-full h-4 w-4 border-2 border-white border-t-transparent" />
                  ) : (
                    <Send className="h-4 w-4" />
                  )}
                  Post
                </button>
              </div>
            </form>
          )}

          {/* Comments List */}
          {prediction.comments?.length > 0 && (
            <div className="space-y-3">
              {prediction.comments.map((comment) => (
                <div key={comment.id} className="pt-3 border-t">
                  <div className="flex items-start justify-between mb-2">
                    <div className="space-y-1">
                      <div className="flex items-center gap-2">
                        <span className="font-medium text-sm">{comment.username || 'Anonymous'}</span>
                        <span className="text-xs text-gray-500">
                          {formatCreatedAt(comment.created_at)}
                        </span>
                      </div>
                      <p className="text-sm text-gray-700">{comment.content}</p>
                    </div>
                  </div>
                  
                  <div className="flex gap-2 mt-2">
                    <ReactionButton
                      type="LIKE"
                      count={comment.likes || 0}
                      isActive={comment.user_reaction === 'LIKE'}
                      onClick={() => handleReaction(comment.id, 'LIKE')}
                    />
                    <ReactionButton
                      type="DISLIKE"
                      count={comment.dislikes || 0}
                      isActive={comment.user_reaction === 'DISLIKE'}
                      onClick={() => handleReaction(comment.id, 'DISLIKE')}
                    />
                    <ReactionButton
                      type="INSIGHTFUL"
                      count={comment.insightful || 0}
                      isActive={comment.user_reaction === 'INSIGHTFUL'}
                      onClick={() => handleReaction(comment.id, 'INSIGHTFUL')}
                    />
                    <ReactionButton
                      type="FUNNY"
                      count={comment.funny || 0}
                      isActive={comment.user_reaction === 'FUNNY'}
                      onClick={() => handleReaction(comment.id, 'FUNNY')}
                    />
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

PredictionCard.propTypes = {
  prediction: PropTypes.shape({
    id: PropTypes.number,
    game: PropTypes.string,
    pick: PropTypes.string,
    potential_points: PropTypes.number,
    reasoning: PropTypes.string,
    game_time: PropTypes.string,
    bet_type: PropTypes.string,
    units: PropTypes.number,
    odds: PropTypes.number,
    result: PropTypes.string,
    earned_points: PropTypes.number,
    comments: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        content: PropTypes.string,
        username: PropTypes.string,
        created_at: PropTypes.string,
        likes: PropTypes.number,
        dislikes: PropTypes.number,
        insightful: PropTypes.number,
        funny: PropTypes.number,
        user_reaction: PropTypes.string
      })
    )
  }),
  onCommentSubmit: PropTypes.func,
  onReactionSubmit: PropTypes.func,
  onRefresh: PropTypes.func
};

export default PredictionCard;