// Import necessary modules and components
import React, { useState, useEffect } from 'react';
import { Trophy, DollarSign, Clock, History, HourglassIcon } from 'lucide-react';
import { Card } from './ui/card';
import { Button } from './ui/button';
import { Dialog, DialogContent } from './ui/dialog';
import { Alert } from './ui/alert';
import { Input } from './ui/input';
import { Label } from './ui/label';
import { Select } from './ui/select';
import { Tabs, TabsList, TabsTrigger, TabsContent } from './ui/tabs';
import TournamentGameCard from './TournamentGameCard';
import api, {
  fetchTournaments,
  fetchTournamentDetails,
  enterTournament,
  submitTournamentPicks,
  createTournament,
  getCurrentUser,
} from '../api';

// Utility functions
const formatToPacificTime = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleString('en-US', {
    timeZone: 'America/Los_Angeles',
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    hour12: true
  });
};

const getPacificTime = (date) => {
  return new Date(date.toLocaleString('en-US', { timeZone: 'America/Los_Angeles' }));
};

const formatGameStatus = (game) => {
  if (game.is_game_over) return 'Completed';
  if (game.status === 'scheduled') return 'Upcoming';
  if (game.period > 0) return `Period ${game.period}`;
  return game.status.charAt(0).toUpperCase() + game.status.slice(1);
};

const formatGameScore = (game) => {
  if (game.period > 0 || game.is_game_over) {
    return `${game.away_score} - ${game.home_score}`;
  }
  return 'vs';
};

const getGameSpread = (game) => {
  if (!game.odds || !game.odds[0]) return null;
  
  const odds = game.odds[0];
  if (odds.point_spread_away === 0.0001 && odds.point_spread_home === 0.0001) {
    return null;
  }
  
  return {
    away: odds.point_spread_away,
    home: odds.point_spread_home,
    awayOdds: odds.point_spread_away_odds,
    homeOdds: odds.point_spread_home_odds
  };
};

// AdminTournamentCreation Component
const InputWithPreventClose = ({ value, onChange, type = "text", ...props }) => (
  <Input
    value={value}
    onChange={(e) => {
      e.stopPropagation();
      onChange(e);
    }}
    onKeyDown={(e) => e.stopPropagation()}
    onFocus={(e) => e.stopPropagation()}
    onBlur={(e) => e.stopPropagation()}
    type={type}
    {...props}
  />
);

const AdminTournamentCreation = ({ onTournamentCreated }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [preventClose, setPreventClose] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [availableGames, setAvailableGames] = useState([]);
  const [filteredGames, setFilteredGames] = useState([]);
  const [selectedGames, setSelectedGames] = useState([]);
  const [filters, setFilters] = useState({
    sport: '',
    date: '',
  });
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    entry_fee: 1.0,
    prize_amount: 5.0,
    start_date: '',
    end_date: '',
  });

  const sportOptions = [
    { value: '1', label: 'NCAA Football' },
    { value: '2', label: 'NFL' },
    { value: '3', label: 'MLB' },
    { value: '4', label: 'NBA' },
    { value: '5', label: 'NCAA Basketball' },
    { value: '6', label: 'NHL' },
    { value: '7', label: 'UFC/MMA' },
    { value: '8', label: 'WNBA' },
    { value: '10', label: 'MLS' },
    { value: '11', label: 'EPL' },
    { value: '12', label: 'Ligue 1' },
    { value: '13', label: 'Bundesliga' },
    { value: '14', label: 'La Liga' },
    { value: '15', label: 'Serie A' },
    { value: '16', label: 'Champions League' },
    { value: '17', label: 'UEFA Euro' },
    { value: '18', label: 'FIFA' },
    { value: '19', label: 'J-League' },
    { value: '20', label: 'IPL Cricket' },
    { value: '21', label: 'T20 Cricket' }
  ];

  useEffect(() => {
    const fetchGames = async () => {
      if (!isOpen) return;
      
      try {
        const currentDate = new Date().toISOString().split('T')[0];
        const futureDate = new Date();
        futureDate.setDate(futureDate.getDate() + 30);
        const endDate = futureDate.toISOString().split('T')[0];

        const response = await api.get('/api/games/', {
          params: {
            date_start: currentDate,
            date_end: endDate,
          },
        });

        if (response.data && Array.isArray(response.data.games)) {
          const upcomingGames = response.data.games.filter(game => {
            return game.status === 'scheduled' && !game.is_game_over;
          });

          upcomingGames.sort((a, b) => new Date(a.event_date) - new Date(b.event_date));
          setAvailableGames(upcomingGames);
          setFilteredGames(upcomingGames);
        }
      } catch (error) {
        console.error('Error fetching games:', error);
        setError('Failed to load available games');
        setAvailableGames([]);
        setFilteredGames([]);
      }
    };

    fetchGames();
  }, [isOpen]);

  useEffect(() => {
    let filtered = [...availableGames];

    if (filters.sport) {
      filtered = filtered.filter((game) => {
        return game.sport_id === parseInt(filters.sport);
      });
    }

    if (filters.date) {
      filtered = filtered.filter((game) => {
        const gameDate = new Date(game.event_date);
        const filterDate = new Date(filters.date + 'T00:00:00');
        const nextDay = new Date(filterDate);
        nextDay.setDate(nextDay.getDate() + 1);

        const gameTimestamp = gameDate.getTime();
        const filterTimestamp = filterDate.getTime();
        const nextDayTimestamp = nextDay.getTime();

        return gameTimestamp >= filterTimestamp && gameTimestamp < nextDayTimestamp;
      });
    }

    filtered = filtered.filter(game => 
      game.status === 'scheduled' && !game.is_game_over
    );

    filtered.sort((a, b) => new Date(a.event_date) - new Date(b.event_date));
    setFilteredGames(filtered);
  }, [filters, availableGames]);

  const toggleGameSelection = (gameId) => {
    setPreventClose(true);
    setSelectedGames((prevSelectedGames) => {
      const isSelected = prevSelectedGames.includes(gameId);
      return isSelected
        ? prevSelectedGames.filter((id) => id !== gameId)
        : [...prevSelectedGames, gameId];
    });
  };

  const handleFilterChange = (field, value) => {
    setFilters((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleFormChange = (field, value) => {
    setPreventClose(true);
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const resetForm = () => {
    setFormData({
      name: '',
      description: '',
      entry_fee: 1.0,
      prize_amount: 5.0,
      start_date: '',
      end_date: '',
    });
    setSelectedGames([]);
    setFilters({ sport: '', date: '' });
    setError(null);
    setPreventClose(false);
  };

  const handleClose = () => {
    if (!isSubmitting) {
      setPreventClose(false);
      setIsOpen(false);
      resetForm();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setIsSubmitting(true);

    try {
      if (!formData.name?.trim()) {
        throw new Error('Tournament name is required');
      }
      if (!formData.description?.trim()) {
        throw new Error('Tournament description is required');
      }
      if (!formData.start_date || !formData.end_date) {
        throw new Error('Start and end dates are required');
      }
      if (selectedGames.length === 0) {
        throw new Error('Please select at least one game');
      }

      const tournamentData = {
        ...formData,
        name: formData.name.trim(),
        description: formData.description.trim(),
        entry_fee: parseFloat(formData.entry_fee) || 1.0,
        prize_amount: parseFloat(formData.prize_amount) || 5.0,
        start_date: new Date(formData.start_date).toISOString(),
        end_date: new Date(formData.end_date).toISOString(),
        game_ids: selectedGames,
        required_picks: selectedGames.length,
      };

      const startDate = new Date(tournamentData.start_date);
      const endDate = new Date(tournamentData.end_date);
      const now = new Date();

      if (startDate < now) {
        throw new Error('Start date cannot be in the past');
      }
      if (endDate <= startDate) {
        throw new Error('End date must be after start date');
      }

      const invalidGames = selectedGames.filter(gameId => {
        const game = availableGames.find(g => g.game_id === gameId);
        return !game || new Date(game.event_date) > endDate || new Date(game.event_date) < startDate;
      });

      if (invalidGames.length > 0) {
        throw new Error('All selected games must be within the tournament date range');
      }

      const response = await api.post('/api/tournaments/admin/create', tournamentData);
      
      if (response.data) {
        setPreventClose(false);
        setIsOpen(false);
        resetForm();
        
        if (onTournamentCreated) {
          await onTournamentCreated();
        }
      }
    } catch (error) {
      console.error('Error creating tournament:', error);
      setError(error.response?.data?.detail || error.message || 'Failed to create tournament');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Button onClick={() => setIsOpen(true)} className="mb-4">
        <Trophy className="w-4 h-4 mr-2" />
        Create Tournament
      </Button>

      <Dialog 
        open={isOpen} 
        onOpenChange={(open) => {
          if (!open && isSubmitting) {
            return;
          }
          if (!open && preventClose) {
            setIsOpen(true);  // Keep dialog open if preventClose is true
            return;
          }
          if (!open) {
            handleClose();
          } else {
            setIsOpen(true);
          }
        }}
      >
        <DialogContent className="max-w-4xl w-full p-6">
          <h2 className="text-xl font-semibold mb-4">Create New Tournament</h2>
          
          {error && (
            <Alert variant="destructive" className="mb-4">
              {error}
            </Alert>
          )}

          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="space-y-4">
                <div>
                  <Label>Tournament Name</Label>
                  <InputWithPreventClose
                    value={formData.name}
                    onChange={(e) => handleFormChange('name', e.target.value)}
                    required
                    className="mt-1"
                  />
                </div>
                <div>
                  <Label>Description</Label>
                  <InputWithPreventClose
                    value={formData.description}
                    onChange={(e) => handleFormChange('description', e.target.value)}
                    required
                    className="mt-1"
                  />
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <Label>Entry Fee ($)</Label>
                    <InputWithPreventClose
                      type="number"
                      min="1"
                      step="0.01"
                      value={formData.entry_fee}
                      onChange={(e) => handleFormChange('entry_fee', e.target.value)}
                      required
                      className="mt-1"
                    />
                  </div>
                  <div>
                    <Label>Prize Amount ($)</Label>
                    <InputWithPreventClose
                      type="number"
                      min="5"
                      step="0.01"
                      value={formData.prize_amount}
                      onChange={(e) => handleFormChange('prize_amount', e.target.value)}
                      required
                      className="mt-1"
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <Label>Start Date</Label>
                    <InputWithPreventClose
                      type="datetime-local"
                      value={formData.start_date}
                      onChange={(e) => handleFormChange('start_date', e.target.value)}
                      required
                      className="mt-1"
                    />
                  </div>
                  <div>
                    <Label>End Date</Label>
                    <InputWithPreventClose
                      type="datetime-local"
                      value={formData.end_date}
                      onChange={(e) => handleFormChange('end_date', e.target.value)}
                      required
                      className="mt-1"
                    />
                  </div>
                </div>
                <div className="bg-gray-50 p-4 rounded-lg">
                  <h3 className="text-md font-medium mb-2">Selected Games ({selectedGames.length})</h3>
                  {selectedGames.length === 0 ? (
                    <p className="text-sm text-gray-500">No games selected.</p>
                  ) : (
                    <ul className="list-disc pl-5 space-y-1">
                      {selectedGames.map((gameId) => {
                        const game = availableGames.find((g) => g.game_id === gameId);
                        return (
                          <li key={gameId} className="text-sm">
                            {game?.away_team} vs {game?.home_team} ({new Date(game?.event_date).toLocaleString()})
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </div>
              </div>

              <div>
                <div className="mb-4">
                  <div className="flex items-center justify-between mb-2">
                    <Label>Filter Games</Label>
                    <Button
                      type="button"
                      variant="outline"
                      onClick={(e) => {
                        e.stopPropagation();
                        setFilters({ sport: '', date: '' });
                        setFilteredGames(availableGames);
                      }}
                      className="text-sm py-1"
                    >
                      Clear Filters
                    </Button>
                  </div>
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <select
                        value={filters.sport}
                        onChange={(e) => {
                          e.stopPropagation();
                          handleFilterChange('sport', e.target.value);
                        }}
                        onFocus={(e) => e.stopPropagation()}
                        onBlur={(e) => e.stopPropagation()}
                        className="w-full rounded-md border border-gray-300 p-2"
                      >
                        <option value="">All Sports</option>
                        {sportOptions.map((sport) => (
                          <option key={sport.value} value={sport.value}>
                            {sport.label}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div>
                      <InputWithPreventClose
                        type="date"
                        value={filters.date}
                        onChange={(e) => handleFilterChange('date', e.target.value)}
                        className="h-full"
                      />
                    </div>
                  </div>
                </div>

                <div className="border rounded-lg h-[500px] overflow-y-auto p-4 space-y-2">
                  {filteredGames.length === 0 ? (
                    <p className="text-gray-500 text-center py-4">
                      No games match the selected filters
                    </p>
                  ) : (
                    filteredGames.map((game) => (
                      <div
                        key={game.game_id}
                        className={`p-4 rounded-lg cursor-pointer border ${
                          selectedGames.includes(game.game_id)
                            ? 'bg-blue-50 border-blue-300'
                            : 'hover:bg-gray-50 border-gray-200'
                        }`}
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleGameSelection(game.game_id);
                        }}
                      >
                        <div className="flex justify-between items-center mb-2">
                          <div className="flex-1">
                            <p className="font-medium">{game.away_team}</p>
                            <p className="font-medium">{game.home_team}</p>
                          </div>
                        </div>
                        <div className="flex justify-between items-center mt-2">
                          <span className="text-sm text-gray-500">
                            {new Date(game.event_date).toLocaleString('en-US', {
                              month: 'short',
                              day: 'numeric',
                              year: 'numeric',
                              hour: 'numeric',
                              minute: '2-digit',
                              hour12: true,
                              timeZone: 'America/Los_Angeles'
                            })} PT
                          </span>
                          <span className="text-xs bg-gray-100 px-2 py-1 rounded">
                            {sportOptions.find(sport => sport.value === game.sport_id.toString())?.label || 'Unknown Sport'}
                          </span>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>

            <div className="flex justify-end gap-2 pt-4 border-t">
              <Button 
                type="button"
                variant="outline" 
                onClick={(e) => {
                  e.stopPropagation();
                  handleClose();
                }}
                disabled={isSubmitting}
              >
                Cancel
              </Button>
              <Button 
                type="submit" 
                disabled={isSubmitting || selectedGames.length === 0}
                className="bg-blue-600 hover:bg-blue-700"
                onClick={(e) => e.stopPropagation()}
              >
                {isSubmitting ? (
                  <div className="flex items-center">
                    <span className="mr-2">Creating...</span>
                  </div>
                ) : (
                  'Create Tournament'
                )}
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

const TournamentSection = () => {
  const [activeTournaments, setActiveTournaments] = useState([]);
  const [pendingTournaments, setPendingTournaments] = useState([]);
  const [closedTournaments, setClosedTournaments] = useState([]);
  const [selectedTournament, setSelectedTournament] = useState(null);
  const [isPicksDialogOpen, setIsPicksDialogOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [userPicks, setUserPicks] = useState({});
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [initialLoad, setInitialLoad] = useState(true);

  const processTournamentResults = async (tournamentId) => {
    try {
      const response = await api.post(`/api/tournaments/admin/${tournamentId}/process-results`);
      if (response.data.success) {
        await fetchAllTournaments();
        return response.data;
      } else {
        console.error('Process tournament failed:', response.data);
        setError('Failed to process tournament results: ' + (response.data.message || 'Unknown error'));
        return null;
      }
    } catch (error) {
      console.error('Process tournament error details:', {
        status: error.response?.status,
        data: error.response?.data,
        message: error.message
      });
      
      // More specific error message based on the response
      const errorMessage = error.response?.data?.detail || 'Unable to process tournament results';
      setError(errorMessage);
      return null;
    }
  };

  const verifyProcessing = async (tournamentId) => {
    const details = await fetchTournamentDetails(tournamentId);
    if (!details.completed) {
      throw new Error('Tournament processing failed verification');
    }
  };

  const checkAndProcessTournaments = async (tournaments) => {
    const now = new Date();
    const processedIds = new Set();
  
    for (const tournament of tournaments) {
      const endDate = new Date(tournament.end_date);
      if (endDate < now && !tournament.completed && !processedIds.has(tournament.id)) {
        try {
          await processTournamentResults(tournament.id);
          processedIds.add(tournament.id);
        } catch (error) {
          console.error(`Error processing tournament ${tournament.id}:`, error);
        }
      }
    }
  };

  const isGamePickable = (game) => {
    if (!game?.event_date) return false;

    const now = new Date();
    const gameTime = new Date(game.event_date);
    const nowPacific = getPacificTime(now).getTime();
    const gameTimePacific = getPacificTime(gameTime).getTime();

    return nowPacific < gameTimePacific;
  };

  const isPickingTimeValid = (tournament) => {
    if (!tournament?.games) return false;
    return tournament.games.some((game) => isGamePickable(game));
  };

  const fetchAllTournaments = async () => {
    try {
      if (initialLoad) {
        setLoading(true);
      }
      const allTournaments = await fetchTournaments();
      const now = new Date();
      const threeDaysAgo = new Date(now.getTime() - (3 * 24 * 60 * 60 * 1000));

      const enrichedTournaments = await Promise.all(
        allTournaments.map(async (tournament) => {
          try {
            const details = await fetchTournamentDetails(tournament.id);
            return {
              ...tournament,
              ...details, // This preserves has_entered and has_made_picks from the API
              games: details.games,
              user_picks: details.user_picks
            };
          } catch (error) {
            console.error(`Error fetching details for tournament ${tournament.id}:`, error);
            return tournament;
          }
        })
      );

      const open = [];
      const pending = [];
      const closed = [];

      for (const tournament of enrichedTournaments) {
        const tournamentEndDate = new Date(tournament.end_date);
        
        if (tournamentEndDate < threeDaysAgo) continue;
        
        if (tournament.completed) {
          closed.push(tournament);
          continue;
        }

        const tournamentStartDate = new Date(tournament.start_date);
        const hasStartedGames = tournament.games?.some(game => {
          const gameTime = new Date(game.event_date);
          return now >= gameTime || game.status === 'in_progress' || game.is_game_over;
        });

        if (hasStartedGames || now > tournamentEndDate) {
          pending.push(tournament);
        } else if (now >= tournamentStartDate && now < tournamentEndDate) {
          open.push(tournament);
        }
      }

      open.sort((a, b) => new Date(a.start_date) - new Date(b.start_date));
      pending.sort((a, b) => new Date(b.end_date) - new Date(a.end_date));
      closed.sort((a, b) => new Date(b.end_date) - new Date(a.end_date));

      setActiveTournaments(open);
      setPendingTournaments(pending);
      setClosedTournaments(closed);
      setError(null);

    } catch (error) {
      console.error('Error fetching tournaments:', error);
      setError('Failed to load tournaments. Please try again later.');
    } finally {
      if (initialLoad) {
        setLoading(false);
        setInitialLoad(false);
      }
    }
  };

  const fetchUserPicks = async (tournamentId) => {
    try {
      const tournamentDetails = await fetchTournamentDetails(tournamentId);
      setSelectedTournament(tournamentDetails);
  
      if (tournamentDetails.user_picks) {
        setUserPicks(tournamentDetails.user_picks);
      } else {
        setUserPicks({});
      }
    } catch (error) {
      console.error('Error fetching user picks:', error);
      setError('Failed to load your picks. Please try again later.');
    }
  };

  useEffect(() => {
    const init = async () => {
      try {
        const userData = await getCurrentUser();
        setUser(userData);
        await fetchAllTournaments();
      } catch (error) {
        console.error('Error during initialization:', error);
        setError('Failed to initialize. Please try again later.');
      }
    };
  
    init();
  
    // Reduce the polling frequency
    const intervalId = setInterval(() => {
      fetchAllTournaments();
    }, 300000); // Poll every 5 minutes instead of every minute
  
    return () => clearInterval(intervalId);
  }, []);


  const handleViewPicks = async (tournament) => {
    try {
      setSelectedTournament(tournament);
      setUserPicks(tournament.user_picks || {});
      setIsPicksDialogOpen(true);
      setError(null);
    } catch (error) {
      console.error('Error viewing picks:', error);
      setError('Failed to load picks. Please try again later.');
    }
  };

  const handleTournamentEntry = async (tournament) => {
    try {
      await enterTournament(tournament.id);
      
      // Fetch the updated tournament details after entry
      const updatedDetails = await fetchTournamentDetails(tournament.id);
      
      // Update the selected tournament with latest details
      setSelectedTournament(updatedDetails);
      
      // Set any user picks (will likely be empty for new entry)
      setUserPicks(updatedDetails.user_picks || {});
      
      // Refresh the full tournament list to update UI
      await fetchAllTournaments();
      
      // Open the picks dialog
      setIsPicksDialogOpen(true);
      setError(null);
    } catch (error) {
      console.error('Error entering tournament:', error);
      setError(
        error.response?.data?.detail || 'Failed to enter tournament. Please try again later.'
      );
    }
  };

  const handlePick = (gameId, team) => {
    setUserPicks((prevPicks) => ({
      ...prevPicks,
      [gameId]: team,
    }));
  };

  const handleSubmitPicks = async () => {
    try {
      if (!selectedTournament?.games?.length) {
        setError('No games available for this tournament.');
        return;
      }
  
      const pickableGames = selectedTournament.games.filter(isGamePickable);
  
      if (pickableGames.length === 0) {
        setError('All games have started. No picks can be made.');
        return;
      }
  
      const picksObject = {};
      pickableGames.forEach((game) => {
        if (userPicks[game.game_id]) {
          picksObject[game.game_id] = userPicks[game.game_id];
        }
      });
  
      if (Object.keys(picksObject).length === 0) {
        setError('Please make at least one pick before submitting.');
        return;
      }
  
      const response = await submitTournamentPicks(selectedTournament.id, { picks: picksObject });
  
      if (response.success) {
        // Remove this setTournamentStatuses call
        setIsPicksDialogOpen(false);
        await fetchAllTournaments();
        setError(null);
      }
    } catch (error) {
      console.error('Pick submission error:', error);
      setError(error.response?.data?.detail || 'Failed to submit picks. Please try again later.');
    }
  };

  const getTournamentStatus = (tournament) => {
    const hasEntered = tournament.has_entered;
    const hasCompletedPicks = tournament.has_made_picks;
  
    // Find earliest game start time
    let earliestGameStart = null;
    if (tournament.games && tournament.games.length > 0) {
      earliestGameStart = tournament.games.reduce((earliest, game) => {
        const gameDate = new Date(game.event_date);
        return earliest === null || gameDate < earliest ? gameDate : earliest;
      }, null);
    }
  
    const now = new Date();
    const tournamentEndDate = new Date(tournament.end_date);
  
    // For completed tournaments
    if (tournament.completed) {
      return {
        buttonText: 'View Results',
        action: () => handleViewPicks(tournament),
        icon: History,
        disabled: false,
      };
    }
  
    // For tournaments that have ended but aren't marked completed
    if (now > tournamentEndDate) {
      return {
        buttonText: 'Processing Results...',
        action: () => handleViewPicks(tournament),
        icon: HourglassIcon,
        disabled: false,
      };
    }
  
    // For tournaments where games have started
    if (earliestGameStart && now >= earliestGameStart) {
      if (!hasEntered) {
        return {
          buttonText: 'Tournament In Progress',
          action: () => null,
          icon: HourglassIcon,
          disabled: true,
        };
      }
      return {
        buttonText: 'View Picks',
        action: () => handleViewPicks(tournament),
        icon: Clock,
        disabled: false,
      };
    }
  
    // For open tournaments
    if (!hasEntered) {
      return {
        buttonText: `Enter Tournament ($${tournament.entry_fee || 5})`,
        action: () => handleTournamentEntry(tournament),
        icon: DollarSign,
        disabled: false,
      };
    }
  
    if (hasEntered && !hasCompletedPicks) {
      return {
        buttonText: 'Make Picks',
        action: () => handleViewPicks(tournament),
        icon: Clock,
        disabled: false,
      };
    }
  
    return {
      buttonText: 'View/Edit Picks',
      action: () => handleViewPicks(tournament),
      icon: Clock,
      disabled: false,
    };
  };

  const renderTournamentCard = (tournament, isPending = false) => {
    const now = new Date();
    const tournamentEndDate = new Date(tournament.end_date);
    
    let buttonProps = {
      icon: DollarSign,
      text: `Enter Tournament ($${tournament.entry_fee})`,
      action: () => handleTournamentEntry(tournament),
      disabled: false
    };
  
    if (tournament.completed) {
      buttonProps = {
        icon: History,
        text: 'View Results',
        action: () => handleViewPicks(tournament),
        disabled: false
      };
    } else if (now > tournamentEndDate) {
      buttonProps = {
        icon: HourglassIcon,
        text: 'Processing Results...',
        action: () => handleViewPicks(tournament),
        disabled: false
      };
    } else if (tournament.has_entered) {
      buttonProps = {
        icon: Clock,
        text: tournament.has_made_picks ? 'View Picks' : 'Make Picks',
        action: () => handleViewPicks(tournament),
        disabled: false
      };
    }

    return (
      <Card className="w-full" key={tournament.id}>
        <div className="p-4">
          <div className="mb-4">
            <h3 className="text-lg font-semibold">{tournament.name}</h3>
            <p className="text-gray-600">{tournament.description}</p>
            <p className="text-sm text-gray-500">
              {isPending ? 'Ends' : 'Starts'}: {formatToPacificTime(isPending ? tournament.end_date : tournament.start_date)}
            </p>
            <p className="text-sm text-green-600 mt-1">
              Prize Amount: ${tournament.prize_amount.toFixed(2)}
            </p>
            {tournament.has_entered && tournament.has_made_picks && (
              <div className="mt-2 text-sm text-blue-600 bg-blue-50 px-3 py-1 rounded-md">
                Picks Submitted ✓
              </div>
            )}
            {isPending && tournament.winner_id && (
              <div className="mt-2 text-sm text-green-600 bg-green-50 px-3 py-1 rounded-md">
                Winner: {tournament.winner_username}
              </div>
            )}
          </div>
  
          <Button 
            className="w-full" 
            onClick={buttonProps.action}
            disabled={buttonProps.disabled}
          >
            <span className="flex items-center gap-2 justify-center">
              <buttonProps.icon className="w-4 h-4" />
              {buttonProps.text}
            </span>
          </Button>
        </div>
      </Card>
    );
  };

  return (
    <div className="space-y-6">
      {user?.is_admin && <AdminTournamentCreation onTournamentCreated={fetchAllTournaments} />}

      {loading && (
        <div className="flex items-center justify-center p-8">
          <p className="text-gray-500">Loading tournaments...</p>
        </div>
      )}

      {!loading && (
        <>
          {error && <Alert>{error}</Alert>}

          <Tabs defaultValue="active" className="w-full">
            <TabsList>
              <TabsTrigger value="active" className="flex items-center gap-2">
                <Trophy className="w-4 h-4" />
                Open ({activeTournaments.length})
              </TabsTrigger>
              <TabsTrigger value="pending" className="flex items-center gap-2">
                <HourglassIcon className="w-4 h-4" />
                In Progress ({pendingTournaments.length})
              </TabsTrigger>
              <TabsTrigger value="closed" className="flex items-center gap-2">
                <History className="w-4 h-4" />
                Completed ({closedTournaments.length})
              </TabsTrigger>
            </TabsList>

            <TabsContent value="active" className="mt-6">
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {activeTournaments.length > 0 ? (
                  activeTournaments.map((tournament) =>
                    renderTournamentCard(tournament, false)
                  )
                ) : (
                  <p className="col-span-full text-center text-gray-500 py-8">
                    No active tournaments available
                  </p>
                )}
              </div>
            </TabsContent>

            <TabsContent value="pending" className="mt-6">
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {pendingTournaments.length > 0 ? (
                  pendingTournaments.map((tournament) => renderTournamentCard(tournament, true))
                ) : (
                  <p className="col-span-full text-center text-gray-500 py-8">
                    No tournaments pending results
                  </p>
                )}
              </div>
            </TabsContent>

            <TabsContent value="closed" className="mt-6">
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {closedTournaments.length > 0 ? (
                  closedTournaments.map((tournament) => renderTournamentCard(tournament, true))
                ) : (
                  <p className="col-span-full text-center text-gray-500 py-8">
                    No recently completed tournaments
                  </p>
                )}
              </div>
            </TabsContent>
          </Tabs>
        </>
      )}

      {selectedTournament && (
        <Dialog open={isPicksDialogOpen} onOpenChange={() => setIsPicksDialogOpen(false)}>
          <DialogContent className="max-w-2xl w-full p-6">
            <div className="mb-4">
              <h2 className="text-xl font-semibold mb-2">
                {selectedTournament.name} -{' '}
                {selectedTournament.has_made_picks
                  ? 'View Your Picks'
                  : Object.keys(userPicks).length === selectedTournament.games?.length
                  ? 'Review Your Picks'
                  : 'Make Your Picks'}
              </h2>
              <p className="text-gray-600">
                {isPickingTimeValid(selectedTournament)
                  ? selectedTournament.has_made_picks
                    ? 'Your picks are locked in.'
                    : 'You can make or modify picks until each game starts.'
                  : 'All games have started. No more picks can be made.'}
              </p>
            </div>

            <div className="space-y-4 max-h-[60vh] overflow-y-auto">
              {selectedTournament.games?.length > 0 ? (
                selectedTournament.games.map((game) => {
                  const canPickGame = isGamePickable(game);
                  return (
                    <TournamentGameCard
                      key={game.game_id}
                      game={game}
                      onPick={(team) => handlePick(game.game_id, team)}
                      selectedTeam={userPicks[game.game_id]}
                      disabled={!canPickGame}
                    />
                  );
                })
              ) : (
                <div className="text-center py-4 text-gray-500">
                  No games available for this tournament.
                </div>
              )}
            </div>

            <div className="flex justify-end gap-2 mt-4">
              <Button variant="outline" onClick={() => setIsPicksDialogOpen(false)}>
                Close
              </Button>
              {isPickingTimeValid(selectedTournament) && (
                <Button
                  onClick={handleSubmitPicks}
                  disabled={
                    !selectedTournament.games?.length ||
                    !selectedTournament.games.some(
                      (game) => isGamePickable(game) && userPicks[game.game_id]
                    )
                  }
                >
                  Submit Picks
                </Button>
              )}
            </div>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
};

export default TournamentSection;