import React, { useState } from 'react';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from './ui/card';
import { Input } from './ui/input';
import { Button } from './ui/button';
import { Alert, AlertDescription } from './ui/alert';
import { DollarSign, Loader2 } from 'lucide-react';
import api from '../api';  // Import your API service

const DepositForm = () => {
    const [amount, setAmount] = useState('');
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      setLoading(true);
      setErrorMessage('');
      
      const depositAmount = parseFloat(amount);
      if (isNaN(depositAmount) || depositAmount < 5) {
        setErrorMessage('Minimum deposit amount is $5.00');
        setLoading(false);
        return;
      }
      
      try {
        console.log('Attempting deposit with amount:', depositAmount);
        
        // Send amount as URL parameter
        const response = await api.post(`/api/payments/deposit?amount=${depositAmount}`);
        console.log('Deposit response:', response.data);
        
        if (response.data?.approval_url) {
          window.location.href = response.data.approval_url;
        } else {
          throw new Error('No approval URL received');
        }
      } catch (err) {
        console.error('Deposit error details:', {
          status: err.response?.status,
          data: err.response?.data,
          message: err.message,
          requestUrl: err.config?.url
        });
  
        let errorMsg = 'Failed to process deposit';
        
        if (err.response?.status === 422) {
          const validationErrors = err.response.data?.detail;
          if (Array.isArray(validationErrors)) {
            errorMsg = validationErrors
              .map(error => {
                const field = error.loc[error.loc.length - 1];
                return `${field}: ${error.msg}`;
              })
              .join(', ');
          }
        } else if (err.response?.data?.detail) {
          errorMsg = err.response.data.detail;
        }
        
        setErrorMessage(errorMsg);
      } finally {
        setLoading(false);
      }
    };
  
    return (
      <Card className="w-full max-w-md mx-auto">
        <CardHeader>
          <CardTitle className="flex items-center gap-2">
            <DollarSign className="w-5 h-5" />
            Add Funds
          </CardTitle>
          <CardDescription>Deposit funds using PayPal</CardDescription>
        </CardHeader>
        
        <CardContent>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="relative">
              <Input
                type="text"
                value={amount}
                onChange={(e) => {
                  const val = e.target.value;
                  if (/^\d*\.?\d{0,2}$/.test(val)) {
                    setAmount(val);
                  }
                }}
                inputMode="decimal"
                placeholder="0.00"
                className="pl-8"
                disabled={loading}
                aria-label="Deposit amount"
              />
              <span className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-500">$</span>
            </div>
            
            {errorMessage && (
              <Alert variant="destructive">
                <AlertDescription>{errorMessage}</AlertDescription>
              </Alert>
            )}
          </form>
        </CardContent>
        
        <CardFooter>
          <Button
            type="submit"
            onClick={handleSubmit}
            disabled={loading || !amount || parseFloat(amount) < 5}
            className="w-full"
          >
            {loading ? (
              <>
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                Processing...
              </>
            ) : (
              'Deposit with PayPal'
            )}
          </Button>
        </CardFooter>
      </Card>
    );
  };
  
  export default DepositForm;