import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import { createGroup } from '../api'; // Adjust the import path as needed

const CreateGroupForm = () => {
    const navigate = useNavigate();
    const [groupName, setGroupName] = useState('');
    const [description, setDescription] = useState('');
    const [price, setPrice] = useState('0.99');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setIsLoading(true);
    
        try {
            const groupData = {
                name: groupName,
                description: description,
                price: parseFloat(price)
            };
    
            // Capture the response from createGroup
            const createdGroup = await createGroup(groupData);
    
            // Navigate to the new group's page using its ID or slug
            navigate(`/groups/${createdGroup.id}`);
        } catch (error) {
            console.error('Failed to create group:', error);
            setError(error.response?.data?.detail || 'Failed to create group');
        } finally {
            setIsLoading(false);
        }
    };
    

    return (
        <div className="min-h-screen bg-gray-50 p-8">
            <button
                onClick={() => navigate(-1)}
                className="flex items-center text-gray-600 hover:text-gray-900 mb-4"
            >
                <ArrowLeft className="h-5 w-5 mr-2" />
                Back to Dashboard
            </button>
            <div className="max-w-2xl mx-auto bg-white rounded-lg shadow-sm p-6">
                <h1 className="text-2xl font-bold mb-6">Create New Group</h1>
                {error && (
                    <div className="mb-4 p-3 bg-red-100 text-red-700 rounded-md">
                        {error}
                    </div>
                )}
                <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            Group Name
                        </label>
                        <input
                            type="text"
                            value={groupName}
                            onChange={(e) => setGroupName(e.target.value)}
                            className="w-full px-3 py-2 border border-gray-300 rounded-md"
                            required
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            Description
                        </label>
                        <textarea
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            className="w-full px-3 py-2 border border-gray-300 rounded-md"
                            rows={4}
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            Price per Month
                        </label>
                        <input
                            type="number"
                            value={price}
                            onChange={(e) => setPrice(e.target.value)}
                            className="w-full px-3 py-2 border border-gray-300 rounded-md"
                            step="0.01"
                            min="0"
                        />
                    </div>
                    <button
                        type="submit"
                        disabled={isLoading}
                        className={`w-full ${
                            isLoading ? 'bg-blue-400' : 'bg-blue-600 hover:bg-blue-700'
                        } text-white py-2 px-4 rounded-md transition-colors`}
                    >
                        {isLoading ? 'Creating...' : 'Create Group'}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default CreateGroupForm;