import React from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from './ui/dialog';
import DepositForm from './PaypalDeposit';

const DepositModal = ({ open, onClose }) => {
    return (
      <Dialog open={open} onOpenChange={onClose}>
        <DialogContent className="sm:max-w-md">
          <DepositForm />
        </DialogContent>
      </Dialog>
    );
  };
  
  export default DepositModal;