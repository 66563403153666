import React, { useState } from 'react';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from './ui/card';
import { Input } from './ui/input';
import { Button } from './ui/button';
import { Alert, AlertDescription } from './ui/alert';
import { Wallet, Loader2 } from 'lucide-react';
import api from '../api';

const WithdrawalForm = ({ onSuccess }) => {
  const [amount, setAmount] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorMessage('');
    setSuccessMessage('');
    
    const withdrawalAmount = parseFloat(amount);
    if (isNaN(withdrawalAmount) || withdrawalAmount < 5) {
      setErrorMessage('Minimum withdrawal amount is $5.00');
      setLoading(false);
      return;
    }
    
    try {
      const response = await api.post('/api/payments/withdraw', {
        amount: withdrawalAmount
      });
      
      if (response.data?.success) {
        setAmount('');
        setSuccessMessage('Withdrawal request submitted successfully');
        if (onSuccess) {
          onSuccess(response.data);
        }
      } else {
        throw new Error(response.data?.message || 'Withdrawal request failed');
      }
    } catch (err) {
      let errorMsg = 'Failed to process withdrawal';
      
      if (err.response?.data?.detail) {
        errorMsg = err.response.data.detail;
      } else if (err.response?.status === 400) {
        errorMsg = 'Insufficient funds for withdrawal';
      } else if (err.response?.status === 500) {
        errorMsg = 'Server error processing withdrawal';
      }
      
      setErrorMessage(errorMsg);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card className="w-full max-w-md mx-auto">
      <CardHeader>
        <CardTitle className="flex items-center gap-2">
          <Wallet className="w-5 h-5" />
          Withdraw Funds
        </CardTitle>
        <CardDescription>Withdraw funds to your PayPal account</CardDescription>
      </CardHeader>
      
      <CardContent>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="relative">
            <Input
              type="text"
              value={amount}
              onChange={(e) => {
                const val = e.target.value;
                if (/^\d*\.?\d{0,2}$/.test(val)) {
                  setAmount(val);
                }
              }}
              inputMode="decimal"
              placeholder="0.00"
              className="pl-8"
              disabled={loading}
              aria-label="Withdrawal amount"
            />
            <span className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-500">$</span>
          </div>
          
          {errorMessage && (
            <Alert variant="destructive">
              <AlertDescription>{errorMessage}</AlertDescription>
            </Alert>
          )}

          {successMessage && (
            <Alert className="bg-green-50 text-green-700 border-green-200">
              <AlertDescription>{successMessage}</AlertDescription>
            </Alert>
          )}
        </form>
      </CardContent>
      
      <CardFooter>
        <Button
          type="submit"
          onClick={handleSubmit}
          disabled={loading || !amount || parseFloat(amount) < 5}
          className="w-full"
        >
          {loading ? (
            <>
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              Processing...
            </>
          ) : (
            'Withdraw to PayPal'
          )}
        </Button>
      </CardFooter>
    </Card>
  );
};

export default WithdrawalForm;