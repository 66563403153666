import React, { useState, useEffect } from 'react';
import { Trophy, TrendingUp, Flame } from 'lucide-react';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "./ui/card";
import { fetchOverallLeaderboard, fetchTrendingSwamis, fetchHotStreakLeaderboard } from '../api';

const LeaderboardDisplay = ({ entry, rank }) => (
  <div className="flex items-center justify-between p-4 bg-white rounded-lg border hover:bg-gray-50 transition-colors">
    <div className="flex items-center space-x-4">
      <div className="flex-shrink-0 w-8 text-center">
        <span className={`font-bold ${rank <= 3 ? 'text-xl text-yellow-500' : 'text-gray-600'}`}>
          #{rank}
        </span>
      </div>
      <div className="flex-shrink-0">
        <div className="h-10 w-10 bg-blue-100 rounded-full flex items-center justify-center">
          <span className="text-blue-700 font-semibold">{entry.username[0].toUpperCase()}</span>
        </div>
      </div>
      <div className="flex-1 min-w-0">
        <p className="text-sm font-medium text-gray-900 truncate">{entry.username}</p>
        <div className="flex space-x-4 text-sm text-gray-500">
          <span>{entry.total_predictions} picks</span>
          <span>{entry.win_rate.toFixed(1)}% win rate</span>
          {entry.current_streak > 0 && (
            <span className="text-green-600">🔥 {entry.current_streak} streak</span>
          )}
        </div>
      </div>
    </div>
    <div className="text-right">
      <p className="text-lg font-bold text-blue-600">
        {entry.total_points.toFixed(1)} pts
      </p>
      {entry.best_sport && (
        <p className="text-sm text-gray-500">
          Best: {entry.best_sport}
        </p>
      )}
    </div>
  </div>
);

const LeaderboardTab = () => {
  const [view, setView] = useState('overall');
  const [leaderboard, setLeaderboard] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchLeaderboard = async () => {
      setLoading(true);
      setError(null);
      try {
        let data;
        switch (view) {
          case 'trending':
            data = await fetchTrendingSwamis();
            break;
          case 'hot':
            data = await fetchHotStreakLeaderboard();
            break;
          default:
            data = await fetchOverallLeaderboard();
        }
        setLeaderboard(data);
      } catch (err) {
        console.error('Error fetching leaderboard:', err);
        setError('Failed to load leaderboard data');
      } finally {
        setLoading(false);
      }
    };

    fetchLeaderboard();
  }, [view]);

  const getLeaderboardTitle = () => {
    switch (view) {
      case 'overall':
        return 'Overall Leaders';
      case 'trending':
        return 'Trending Swamis';
      case 'hot':
        return 'Hot Streaks';
      default:
        return 'Leaderboard';
    }
  };

  const getLeaderboardIcon = () => {
    switch (view) {
      case 'overall':
        return <Trophy className="h-6 w-6 text-yellow-500" />;
      case 'trending':
        return <TrendingUp className="h-6 w-6 text-green-500" />;
      case 'hot':
        return <Flame className="h-6 w-6 text-red-500" />;
      default:
        return <Trophy className="h-6 w-6 text-yellow-500" />;
    }
  };

  return (
    <Card>
      <CardHeader>
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-3">
            {getLeaderboardIcon()}
            <CardTitle>{getLeaderboardTitle()}</CardTitle>
          </div>
          <div className="flex space-x-2">
            <button
              onClick={() => setView('overall')}
              className={`px-3 py-1 rounded-md text-sm ${
                view === 'overall'
                  ? 'bg-blue-100 text-blue-700'
                  : 'text-gray-600 hover:bg-gray-100'
              }`}
            >
              Overall
            </button>
            <button
              onClick={() => setView('trending')}
              className={`px-3 py-1 rounded-md text-sm ${
                view === 'trending'
                  ? 'bg-blue-100 text-blue-700'
                  : 'text-gray-600 hover:bg-gray-100'
              }`}
            >
              Trending
            </button>
            <button
              onClick={() => setView('hot')}
              className={`px-3 py-1 rounded-md text-sm ${
                view === 'hot'
                  ? 'bg-blue-100 text-blue-700'
                  : 'text-gray-600 hover:bg-gray-100'
              }`}
            >
              Hot Streak
            </button>
          </div>
        </div>
      </CardHeader>
      <CardContent>
        <div className="space-y-3">
          {loading ? (
            <div className="text-center py-8">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600 mx-auto"></div>
              <p className="mt-2 text-gray-500">Loading leaderboard...</p>
            </div>
          ) : error ? (
            <div className="text-center py-8 text-red-500">{error}</div>
          ) : leaderboard.length > 0 ? (
            leaderboard.map((entry, index) => (
              <LeaderboardDisplay 
                key={entry.user_id || index} 
                entry={{
                  ...entry,
                  total_points: Number(entry.total_points) || 0,
                  win_rate: Number(entry.win_rate) || 0,
                  total_predictions: Number(entry.total_predictions) || 0,
                  current_streak: Number(entry.current_streak) || 0,
                }} 
                rank={index + 1} 
              />
            ))
          ) : (
            <div className="text-center py-8 text-gray-500">No data available</div>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default LeaderboardTab;