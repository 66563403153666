// First, let's do all the imports
import React, { useEffect, useState, useMemo, useCallback, Suspense } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Bell,
  Trophy,
  Users,
  Activity,
  ChevronRight,
  DollarSign,
  Banknote,
  Wallet
} from 'lucide-react';
import {
  getCurrentUser,
  createPredictionComment,
  reactToPredictionComment,
  fetchOverallLeaderboard,
  fetchTrendingSwamis,
  fetchHotStreakLeaderboard,
  fetchGames,
} from '../api';
import {
  useApi,
  useGroups,
  usePredictions,
  useDashboardData,
  apiCache,
} from '../hooks/useApi';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from './ui/dialog';
import { Button } from './ui/button';
import { Card } from './ui/card';
import PredictionCard from './PredictionCard';
import NewPredictionForm from './NewPredictionForm';
import GameCard from './GameCard';
import LeaderboardTab from './LeaderboardTab';
import TournamentSection from './TournamentSection';
import SwamiLogo from '../SwamiOriginal.png';
import DepositForm from './PaypalDeposit';
import DepositModal from './DepositModal';
import TrendingScroller from './TrendingScroller';
import WithdrawalModal from './WithdrawalModal';

// Memoized Components
const MemoizedGameCard = React.memo(GameCard);
const MemoizedPredictionCard = React.memo(PredictionCard);

// Utility function to get game status
const getGameStatus = (game) => {
  const status = game.status?.toLowerCase() || '';
  const gameDate = new Date(game.event_date);
  const now = new Date();

  if (status === 'in_progress' || status === 'in-progress' || game.period > 0) {
    return 'inProgress';
  }
  if (
    status === 'completed' ||
    status === 'final' ||
    status.includes('complete') ||
    game.is_game_over === true
  ) {
    return 'final';
  }
  if (gameDate > now || status === 'scheduled' || status === 'upcoming') {
    return 'future';
  }

  console.log(`Unhandled game status for game ${game.id}:`, {
    status,
    period: game.period,
    isGameOver: game.is_game_over,
    eventDate: game.event_date,
  });

  return 'unknown';
};

// Sport configuration object
const allSportTabs = [
  { id: 1, name: 'NCAA Football' },
  { id: 2, name: 'NFL' },
  { id: 3, name: 'MLB' },
  { id: 4, name: 'NBA' },
  { id: 5, name: 'NCAA Basketball' },
  { id: 6, name: 'NHL' },
  { id: 7, name: 'UFC/MMA' },
  { id: 8, name: 'WNBA' },
  { id: 10, name: 'MLS' },
  { id: 11, name: 'EPL' },
  { id: 12, name: 'Ligue 1' },
  { id: 13, name: 'Bundesliga' },
  { id: 14, name: 'La Liga' },
  { id: 15, name: 'Serie A' },
  { id: 16, name: 'Champions League' },
  { id: 17, name: 'UEFA Euro' },
  { id: 18, name: 'FIFA' },
  { id: 19, name: 'J-League' },
  { id: 20, name: 'IPL Cricket' },
  { id: 21, name: 'T20 Cricket' },
];

// Optimized PickGamesSection Component
const PickGamesSection = React.memo(({ games }) => {
  const [selectedGame, setSelectedGame] = useState(null);
  const [selectedPrediction, setSelectedPrediction] = useState(null);
  const [activeTab, setActiveTab] = useState('inProgress');
  const [activeSportId, setActiveSportId] = useState(null);

  const { data: predictions = [], refresh: refreshPredictions } = usePredictions([], {
    skip: !selectedGame && !selectedPrediction,
  });

  // Modified availableSports computation to prioritize sports with in-progress games
  const availableSports = useMemo(() => {
    if (!games?.length) return [];
    
    // Create a map of sport IDs to their games
    const sportGamesMap = games.reduce((acc, game) => {
      if (!acc[game.sport_id]) {
        acc[game.sport_id] = [];
      }
      acc[game.sport_id].push(game);
      return acc;
    }, {});

    // For each sport, determine if it has in-progress games
    const sportsWithStatus = allSportTabs
      .filter(sport => sportGamesMap[sport.id])
      .map(sport => {
        const sportGames = sportGamesMap[sport.id];
        const hasInProgressGames = sportGames.some(game => {
          const status = getGameStatus(game);
          return status === 'inProgress';
        });
        return {
          ...sport,
          hasInProgressGames,
          gameCount: sportGames.length
        };
      });

    // Sort sports: first by whether they have in-progress games, then by total game count
    return sportsWithStatus.sort((a, b) => {
      if (a.hasInProgressGames && !b.hasInProgressGames) return -1;
      if (!a.hasInProgressGames && b.hasInProgressGames) return 1;
      return b.gameCount - a.gameCount; // Secondary sort by total game count
    });
  }, [games]);

  // Organize games by status
  const organizedGames = useMemo(() => {
    if (!games || !activeSportId) return { inProgress: [], final: [], future: [] };

    return games.reduce(
      (acc, game) => {
        if (game.sport_id !== activeSportId) return acc;
        const status = getGameStatus(game);
        acc[status].push(game);
        return acc;
      },
      { inProgress: [], final: [], future: [] }
    );
  }, [games, activeSportId]);

  // Sort games within each category
  const sortedGames = useMemo(
    () => ({
      inProgress: [...organizedGames.inProgress].sort(
        (a, b) => new Date(a.event_date) - new Date(b.event_date)
      ),
      final: [...organizedGames.final].sort(
        (a, b) => new Date(b.event_date) - new Date(a.event_date)
      ),
      future: [...organizedGames.future].sort(
        (a, b) => new Date(a.event_date) - new Date(b.event_date)
      ),
    }),
    [organizedGames]
  );

  // Create status tabs with counts
  const statusTabs = useMemo(
    () => [
      { id: 'inProgress', name: 'In Progress', count: organizedGames.inProgress.length },
      { id: 'final', name: 'Final', count: organizedGames.final.length },
      { id: 'future', name: 'Future', count: organizedGames.future.length },
    ],
    [organizedGames]
  );

  // Effect for setting initial sport ID - modified to select first sport with in-progress games
  useEffect(() => {
    if (!activeSportId && availableSports.length > 0) {
      const firstInProgressSport = availableSports.find(sport => sport.hasInProgressGames);
      setActiveSportId(firstInProgressSport?.id || availableSports[0].id);
    }
  }, [availableSports, activeSportId]);

  // Memoized handlers
  const handleViewPrediction = useCallback((prediction) => {
    setSelectedPrediction(prediction);
  }, []);

  const handleRefresh = useCallback(async () => {
    await refreshPredictions();
  }, [refreshPredictions]);

  const handleCommentSubmit = useCallback(
    async (predictionId, comment) => {
      try {
        const result = await createPredictionComment(predictionId, comment);
        handleRefresh();
        return result;
      } catch (err) {
        console.error('Failed to post comment:', err);
        throw err;
      }
    },
    [handleRefresh]
  );

  const handleReactionSubmit = useCallback(
    async (predictionId, commentId, reactionType) => {
      try {
        const result = await reactToPredictionComment(predictionId, commentId, reactionType);
        handleRefresh();
        return result;
      } catch (err) {
        console.error('Failed to add reaction:', err);
        throw err;
      }
    },
    [handleRefresh]
  );

  if (availableSports.length === 0) {
    return (
      <div className="text-center py-8">
        <p className="text-gray-500">No games available for any sports at this time.</p>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {/* Sport Tabs */}
      <div className="border-b border-gray-200 mb-6">
        <nav className="-mb-px flex space-x-8 overflow-x-auto">
          {availableSports.map((sport) => (
            <button
              key={sport.id}
              onClick={() => setActiveSportId(sport.id)}
              className={`${
                activeSportId === sport.id
                  ? 'border-blue-500 text-blue-600'
                  : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
              } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm transition-colors relative`}
            >
              {sport.name}
            </button>
          ))}
        </nav>
      </div>

      {/* Status Tabs */}
      <div className="border-b border-gray-200 mb-6">
        <nav className="-mb-px flex space-x-8">
          {statusTabs.map((tab) => (
            <button
              key={tab.id}
              onClick={() => setActiveTab(tab.id)}
              className={`${
                activeTab === tab.id
                  ? 'border-blue-500 text-blue-600'
                  : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
              } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm transition-colors flex items-center gap-2`}
            >
              {tab.name}
              <span
                className={`${
                  activeTab === tab.id ? 'bg-blue-100 text-blue-600' : 'bg-gray-100 text-gray-600'
                } px-2 py-0.5 rounded-full text-xs`}
              >
                {tab.count}
              </span>
            </button>
          ))}
        </nav>
      </div>

      {/* Dialogs */}
      <Dialog open={!!selectedGame} onOpenChange={() => setSelectedGame(null)}>
        <DialogContent className="max-w-2xl max-h-[90vh] overflow-y-auto">
          <Suspense fallback={<div>Loading form...</div>}>
            <NewPredictionForm
              preselectedGame={selectedGame}
              onClose={() => setSelectedGame(null)}
              onSuccess={() => {
                setSelectedGame(null);
                handleRefresh();
              }}
            />
          </Suspense>
        </DialogContent>
      </Dialog>

      <Dialog open={!!selectedPrediction} onOpenChange={() => setSelectedPrediction(null)}>
        <DialogContent className="max-w-2xl max-h-[90vh] overflow-y-auto">
          <Suspense fallback={<div>Loading prediction...</div>}>
            <MemoizedPredictionCard
              prediction={selectedPrediction}
              onCommentSubmit={handleCommentSubmit}
              onReactionSubmit={handleReactionSubmit}
              onRefresh={handleRefresh}
            />
          </Suspense>
        </DialogContent>
      </Dialog>

      {/* Games Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {sortedGames[activeTab]?.map((game) => {
          const existingPrediction = predictions?.find(
            (p) =>
              p.game_id === game.id ||
              p.game === `${game.home_team} vs ${game.away_team}` ||
              p.game === `${game.away_team} vs ${game.home_team}`
          );

          return (
            <MemoizedGameCard
              key={game.id || game.game_id}
              game={game}
              onPickGame={setSelectedGame}
              onViewPrediction={handleViewPrediction}
              existingPrediction={existingPrediction}
            />
          );
        })}
      </div>

      {/* Empty State */}
      {(!sortedGames[activeTab] || sortedGames[activeTab].length === 0) && (
        <div className="text-center py-8">
          <p className="text-gray-500">
            No {activeTab.replace(/([A-Z])/g, ' $1').toLowerCase()} games available for{' '}
            {availableSports.find((sport) => sport.id === activeSportId)?.name || 'this sport'}.
          </p>
        </div>
      )}
    </div>
  );
});

// Memoized GroupCard Component
const GroupCard = React.memo(({ group, user }) => {
  const navigate = useNavigate();

  const handleViewDetails = useCallback(() => {
    navigate(`/groups/${group.id}`);
  }, [navigate, group.id]);

  return (
    <div className="border rounded-lg p-4 hover:shadow-md transition-shadow">
      <div className="flex justify-between items-center">
        <div>
          <h3 className="font-semibold">{group.name}</h3>
          {group.swami_id === user?.id && (
            <span className="inline-block px-2 py-1 text-xs bg-purple-100 text-purple-800 rounded-full mt-1">
              Swami
            </span>
          )}
        </div>
        <button
          onClick={handleViewDetails}
          className="inline-flex items-center gap-2 text-blue-600 text-sm hover:bg-blue-50 px-3 py-2 rounded-md transition-colors duration-200"
        >
          View Details
          <ChevronRight className="h-4 w-4" />
        </button>
      </div>
      <p className="text-gray-600 mt-2">{group.description}</p>
      <div className="mt-2 flex items-center text-sm text-gray-500">
        <Users className="h-4 w-4 mr-1" />
        <span>{group.member_count || 0} members</span>
      </div>
    </div>
  );
});

// Error Boundary Component
class DashboardErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    console.error('Dashboard Error:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="flex items-center justify-center h-screen">
          <div className="p-6 bg-white rounded-lg shadow-lg">
            <h2 className="text-xl font-bold text-red-600 mb-2">Something went wrong</h2>
            <p className="text-gray-600">
              Please try refreshing the page or contact support if the issue persists.
            </p>
            <button
              onClick={() => window.location.reload()}
              className="mt-4 px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
            >
              Refresh Page
            </button>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

// Export the memoized Dashboard with error boundary
export default React.memo(() => (
  <DashboardErrorBoundary>
    <Dashboard />
  </DashboardErrorBoundary>
));

// Main Dashboard Component
const Dashboard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState('pick-games');
  const [notification, setNotification] = useState(null);
  const [toast, setToast] = useState(null);
  const [depositModalOpen, setDepositModalOpen] = useState(false);
  const [withdrawalModalOpen, setWithdrawalModalOpen] = useState(false);

  // Use optimized data fetching
  const {
    data: dashboardData,
    loading: initialLoading,
    error: initialError,
    refresh: refreshDashboard,
  } = useDashboardData();

  const { startedGroups, memberGroups, popularGroups, loading: groupsLoading } = useGroups();

  // Get group IDs for predictions
  const allGroupIds = useMemo(
    () => [...(startedGroups || []), ...(memberGroups || [])].map((g) => g.id),
    [startedGroups, memberGroups]
  );

  // Only fetch predictions when needed
  const {
    data: predictions = [],
    loading: predictionsLoading,
    error: predictionsError,
    refresh: refreshPredictions,
  } = usePredictions(allGroupIds, {
    skip: activeTab !== 'my-picks',
  });

  // Effects for Dashboard component
  // In your Dashboard component, find this useEffect:

// Remove or modify this effect
  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    if (!token) {
      navigate('/login', { replace: true, state: { message: 'Please log in.' } });
    }
  }, [navigate]);

  // Replace with this simpler effect that just handles messages
  useEffect(() => {
    if (location.state?.message) {
      setNotification({ message: location.state.message });
      navigate(location.pathname, { replace: true, state: {} });
    }
  }, [location.state, navigate, location.pathname]);

  // Memoized handlers
  const handleCommentSubmit = useCallback(
    async (predictionId, content) => {
      try {
        const comment = await createPredictionComment(predictionId, content);
        refreshPredictions();
        setToast({ type: 'success', message: 'Comment posted successfully' });
        return comment;
      } catch (err) {
        console.error('Failed to post comment:', err);
        setToast({ type: 'error', message: 'Failed to post comment' });
        throw err;
      }
    },
    [refreshPredictions]
  );

  const handleReactionSubmit = useCallback(
    async (predictionId, commentId, reactionType) => {
      try {
        const response = await reactToPredictionComment(predictionId, commentId, reactionType);
        refreshPredictions();
        return response;
      } catch (err) {
        console.error('Failed to add reaction:', err);
        setToast({ type: 'error', message: 'Failed to add reaction' });
        throw err;
      }
    },
    [refreshPredictions]
  );

  // Add keyboard shortcuts
  useEffect(() => {
    let keysPressed = {};

    const handleKeyDown = (event) => {
      keysPressed[event.key] = true;

      if (keysPressed['g']) {
        switch (event.key) {
          case 'p':
            setActiveTab('pick-games');
            break;
          case 'm':
            setActiveTab('my-picks');
            break;
          case 'g':
            setActiveTab('groups');
            break;
          case 't':
            setActiveTab('tournaments');
            break;
          case 'l':
            setActiveTab('leaderboard');
            break;
          default:
            break;
        }
      }
    };

    const handleKeyUp = (event) => {
      delete keysPressed[event.key];
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [setActiveTab]);

  // Add auto-refresh for active games
  useEffect(() => {
    let refreshInterval;

    if (activeTab === 'pick-games') {
      refreshInterval = setInterval(() => {
        refreshDashboard();
      }, 30000); // Refresh every 30 seconds
    }

    return () => {
      if (refreshInterval) {
        clearInterval(refreshInterval);
      }
    };
  }, [activeTab, refreshDashboard]);

  // Memoized render functions
  const renderGroupsTab = useCallback(() => (
    <div className="space-y-8">
      {/* Started Groups Section */}
      <div className="mb-8">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-semibold">Groups You've Started</h2>
          <button
            onClick={() => navigate('/groups/new')}
            className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 transition-colors"
          >
            Create Group
          </button>
        </div>
        {startedGroups?.length > 0 ? (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {startedGroups.map((group) => (
              <GroupCard key={group.id} group={group} user={dashboardData?.user} />
            ))}
          </div>
        ) : (
          <div className="text-center py-8 bg-gray-50 rounded-lg border border-gray-200">
            <div className="mx-auto w-12 h-12 flex items-center justify-center bg-gray-100 rounded-full mb-4">
              <Users className="h-6 w-6 text-gray-400" />
            </div>
            <h3 className="text-sm font-medium text-gray-900 mb-1">No Groups Started</h3>
            <p className="text-sm text-gray-500 mb-4">
              Create your first group and start making predictions!
            </p>
          </div>
        )}
      </div>

      {/* Member Groups Section */}
      <div className="mb-8">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-semibold">Groups You're a Member Of</h2>
          <button
            onClick={() => navigate('/groups/browse')}
            className="inline-flex items-center px-4 py-2 text-sm font-medium text-blue-600 bg-blue-50 rounded-md hover:bg-blue-100 transition-colors"
          >
            Browse Groups
          </button>
        </div>
        {memberGroups?.length > 0 ? (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {memberGroups.map((group) => (
              <GroupCard key={group.id} group={group} user={dashboardData?.user} />
            ))}
          </div>
        ) : (
          <div className="text-center py-8 bg-gray-50 rounded-lg border border-gray-200">
            <div className="mx-auto w-12 h-12 flex items-center justify-center bg-gray-100 rounded-full mb-4">
              <Users className="h-6 w-6 text-gray-400" />
            </div>
            <h3 className="text-sm font-medium text-gray-900 mb-1">Not a Member of Any Groups</h3>
            <p className="text-sm text-gray-500 mb-4">
              Join groups to participate in predictions!
            </p>
          </div>
        )}
      </div>

      {/* Popular Groups Section */}
      <div>
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-semibold">Popular Groups</h2>
          {popularGroups?.length > 0 && (
            <button
              onClick={() => navigate('/groups/popular')}
              className="inline-flex items-center gap-2 text-sm text-gray-600 hover:text-gray-900"
            >
              View All
              <ChevronRight className="h-4 w-4" />
            </button>
          )}
        </div>
        {popularGroups?.length > 0 ? (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {popularGroups.map((group) => (
              <GroupCard key={group.id} group={group} user={dashboardData?.user} />
            ))}
          </div>
        ) : (
          <div className="text-center py-8 bg-gray-50 rounded-lg border border-gray-200">
            <div className="mx-auto w-12 h-12 flex items-center justify-center bg-gray-100 rounded-full mb-4">
              <Trophy className="h-6 w-6 text-gray-400" />
            </div>
            <h3 className="text-sm font-medium text-gray-900 mb-1">No Popular Groups</h3>
            <p className="text-sm text-gray-500">Check back later for trending groups!</p>
          </div>
        )}
      </div>
    </div>
  ), [startedGroups, memberGroups, popularGroups, dashboardData?.user, navigate]);

  const renderContent = useCallback(() => {
    switch (activeTab) {
      case 'pick-games':
        return (
          <Suspense fallback={<div>Loading games...</div>}>
            <PickGamesSection games={dashboardData?.games} />
          </Suspense>
        );
      case 'my-picks':
        return (
          <div className="space-y-6">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-lg font-semibold">Recent Predictions</h2>
            </div>

            {predictions.length > 0 ? (
              <div className="space-y-4">
                {predictions.map((prediction) => (
                  <Suspense key={`prediction-${prediction.id}`} fallback={<div>Loading prediction...</div>}>
                    <MemoizedPredictionCard
                      prediction={prediction}
                      onCommentSubmit={handleCommentSubmit}
                      onReactionSubmit={handleReactionSubmit}
                    />
                  </Suspense>
                ))}
              </div>
            ) : (
              <p className="text-gray-500 text-center py-8">No predictions found.</p>
            )}
          </div>
        );
      case 'groups':
        return renderGroupsTab();
      case 'tournaments':
        return (
          <Suspense fallback={<div>Loading tournaments...</div>}>
            <TournamentSection />
          </Suspense>
        );
      case 'leaderboard':
        return (
          <Suspense fallback={<div>Loading leaderboard...</div>}>
            <LeaderboardTab />
          </Suspense>
        );
      default:
        return null;
    }
  }, [
    activeTab,
    dashboardData?.games,
    predictions,
    handleCommentSubmit,
    handleReactionSubmit,
    renderGroupsTab,
  ]);

  const loading =
    initialLoading || groupsLoading || (activeTab === 'my-picks' && predictionsLoading);
  const error = initialError || (activeTab === 'my-picks' && predictionsError);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="p-6 bg-white rounded-lg shadow-lg">
          <p className="text-gray-600 text-center">Loading dashboard data...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="p-6 bg-white rounded-lg shadow-lg">
          <p className="text-red-500 text-center">{error.message}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-white">
      {/* Header with SWAMI Logo */}
      <div className="bg-white p-4 text-black">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex justify-between items-center">
          <img 
            src={SwamiLogo} 
            alt="SWAMI" 
            className="h-8 w-auto"
          />
          <div className="flex items-center space-x-4">
            <button className="p-2 rounded-full bg-gray-800 hover:bg-gray-700 transition-colors">
              <Bell className="h-6 w-6 text-white" />
            </button>
            <button
              onClick={() => navigate('/profile')}
              className="px-4 py-2 text-sm font-medium text-black hover:bg-gray-800 rounded-md transition-colors"
            >
              Profile
            </button>
          </div>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {/* User Welcome Section */}
        <div className="mb-8">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-4">
              <div>
                <h1 className="text-2xl font-bold text-gray-900">
                  Welcome back, {dashboardData?.user?.username || 'Swami'}!
                </h1>
                <p className="text-gray-500">
                  {dashboardData?.user?.subscription_tier
                    ? `${dashboardData.user.subscription_tier} Member`
                    : 'Free Member'}
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Stats Grid */}
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-8">
          {/* My Total Points */}
          <div className="bg-white p-4 rounded-lg border border-gray-200 hover:shadow-lg transition-shadow flex items-center">
            <Trophy className="h-8 w-8 text-black" />
            <div className="ml-4 flex flex-col">
              <span className="text-base text-gray-600">My Total Points</span>
              <span className="text-xl font-bold text-gray-900">
                {dashboardData?.user?.total_points?.toFixed(1) || '0.0'}
              </span>
            </div>
          </div>

          {/* My Active Groups */}
          <div className="bg-white p-4 rounded-lg border border-gray-200 hover:shadow-lg transition-shadow flex items-center">
            <Users className="h-8 w-8 text-black" />
            <div className="ml-4 flex flex-col">
              <span className="text-base text-gray-600">My Active Groups</span>
              <span className="text-xl font-bold text-gray-900">
                {memberGroups?.length || 0}
              </span>
            </div>
          </div>

          {/* My Win Rate */}
          <div className="bg-white p-4 rounded-lg border border-gray-200 hover:shadow-lg transition-shadow flex items-center">
            <Activity className="h-8 w-8 text-black" />
            <div className="ml-4 flex flex-col">
              <span className="text-base text-gray-600">My Win Rate</span>
              <span className="text-xl font-bold text-gray-900">
                {dashboardData?.user?.win_rate?.toFixed(1) || '0.0'}%
              </span>
            </div>
          </div>

          {/* My Balance */}
          <div className="bg-white p-4 rounded-lg border border-gray-200 hover:shadow-lg transition-shadow flex items-center justify-between">
            <div className="flex items-center">
              <Wallet className="h-8 w-8 text-black" />
              <div className="ml-4 flex flex-col">
                <span className="text-base text-gray-600">My Balance</span>
                <span className="text-xl font-bold text-gray-900">
                  ${dashboardData?.user?.available_balance?.toFixed(2) || '0.00'}
                </span>
              </div>
            </div>
            <div className="flex flex-col space-y-2">
              <Button
                onClick={() => setDepositModalOpen(true)}
                className="bg-green-600 hover:bg-green-700 text-white px-3 py-1 text-sm"
                size="sm"
              >
                Add $
              </Button>
              <Button
                onClick={() => setWithdrawalModalOpen(true)}
                className="bg-red-600 hover:bg-red-700 text-white px-3 py-1 text-sm"
                size="sm"
              >
                Take $
              </Button>
            </div>

          </div>
        </div>

        {/* Add both modals after the stats grid */}
          <DepositModal 
            open={depositModalOpen} 
            onClose={() => {
              setDepositModalOpen(false);
              refreshDashboard();
            }} 
          />
          <WithdrawalModal
            open={withdrawalModalOpen}
            onClose={() => {
              setWithdrawalModalOpen(false);
              refreshDashboard();
            }}
          />

        {/* Navigation Tabs */}
        <div className="mb-6">
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex space-x-8">
              {['pick-games', 'my-picks', 'groups', 'tournaments', 'leaderboard'].map((tab) => (
                <button
                  key={tab}
                  onClick={() => setActiveTab(tab)}
                  className={`${
                    activeTab === tab
                      ? 'border-black text-black'
                      : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
                  } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm transition-colors`}
                >
                  {tab
                    .split('-')
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(' ')}
                </button>
              ))}
            </nav>
          </div>
        </div>

        {/* Main Content */}
        <div className="bg-white rounded-lg border border-gray-200 p-6">{renderContent()}</div>

        {/* Notification Modal */}
        <Dialog open={!!notification} onOpenChange={() => setNotification(null)}>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>{notification?.title}</DialogTitle>
              <DialogDescription>{notification?.message}</DialogDescription>
            </DialogHeader>
            <div className="flex justify-end">
              <Button
                onClick={() => setNotification(null)}
                className="bg-black hover:bg-gray-800 text-white"
              >
                Close
              </Button>
            </div>
          </DialogContent>
        </Dialog>

        {/* Success/Error Toast */}
        {toast && (
          <div
            className={`fixed bottom-4 right-4 p-4 rounded-lg shadow-lg ${
              toast.type === 'success' ? 'bg-black' : 'bg-red-500'
            } text-white transform transition-transform duration-300 ease-in-out`}
            style={{
              zIndex: 9999,
              animation: 'fade-in-up 0.3s ease-out',
            }}
          >
            {toast.message}
          </div>
        )}
      </div>
    </div>
  );
};