import React from 'react';

const Select = React.forwardRef(({ 
  children,
  className = '',
  value,
  onChange,
  ...props 
}, ref) => {
  return (
    <select
      ref={ref}
      className={`w-full p-2 border rounded-md bg-white ${className}`}
      value={value}
      onChange={onChange}
      {...props}
    >
      {children}
    </select>
  );
});

Select.displayName = 'Select';

export { Select };