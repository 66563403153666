import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from './ui/dialog';
import { ScrollArea } from './ui/scroll-area';
import { fetchCurrentTOS, fetchTOSByVersion } from '../api';

const TermsOfService = ({ 
    isOpen = false, 
    onClose = () => {}, 
    showAccept = false, 
    onAccept = () => {}, 
    version = null 
}) => {
    const [termsContent, setTermsContent] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchTOS = async () => {
        setLoading(true);
        setError(null);

        try {
            const tos = version ? 
                await fetchTOSByVersion(version) : 
                await fetchCurrentTOS();
            
            if (!tos || !tos.content) {
                throw new Error('Invalid TOS content received');
            }
            
            setTermsContent(tos.content);
        } catch (err) {
            console.error('Error fetching TOS:', err);
            setError('Failed to load Terms of Service. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (isOpen) {
            fetchTOS();
        }
    }, [isOpen, version]);

    // Loading spinner component
    const LoadingSpinner = () => (
        <div className="flex justify-center items-center py-8">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
        </div>
    );

    // Error display component
    const ErrorDisplay = () => (
        <div className="flex flex-col items-center space-y-4 py-8">
            <p className="text-red-600">{error}</p>
            <button
                onClick={fetchTOS}
                className="px-4 py-2 bg-black text-white rounded-lg hover:bg-gray-800 transition-colors"
            >
                Retry
            </button>
        </div>
    );

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className="sm:max-w-3xl max-h-[90vh]">
                <DialogHeader>
                    <DialogTitle className="text-2xl font-bold">
                        Terms of Service {version ? `(Version: ${version})` : ''}
                    </DialogTitle>
                </DialogHeader>

                <ScrollArea className="mt-4 h-[60vh]">
                    <div className="pr-4">
                        {loading ? (
                            <LoadingSpinner />
                        ) : error ? (
                            <ErrorDisplay />
                        ) : termsContent ? (
                            <div 
                                className="space-y-4 prose prose-sm max-w-none" 
                                dangerouslySetInnerHTML={{ __html: termsContent }} 
                            />
                        ) : (
                            <p className="text-center text-gray-500 py-8">
                                No Terms of Service available at this time.
                            </p>
                        )}
                    </div>
                </ScrollArea>

                <div className="mt-6 flex justify-end space-x-4">
                    <button
                        onClick={onClose}
                        className="px-4 py-2 text-gray-600 hover:text-gray-900 transition-colors"
                    >
                        Close
                    </button>
                    {showAccept && (
                        <button
                            onClick={onAccept}
                            className="px-4 py-2 bg-black text-white rounded-lg hover:bg-gray-800 transition-colors"
                            disabled={loading || error}
                        >
                            Accept Terms
                        </button>
                    )}
                </div>
            </DialogContent>
        </Dialog>
    );
};

// Type checking with TypeScript-style comments for better IDE support
TermsOfService.displayName = 'TermsOfService';

export default TermsOfService;