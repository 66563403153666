import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Mail, CheckCircle, XCircle, ArrowRight, Loader2 } from 'lucide-react';
import { Card, CardHeader, CardContent } from './ui/card';
import { Alert, AlertDescription } from './ui/alert';

const EmailVerification = () => {
    const [verificationStatus, setVerificationStatus] = useState('initializing');
    const [error, setError] = useState(null);
    const [message, setMessage] = useState('');
    const [isResending, setIsResending] = useState(false);
    const [hasAttemptedVerification, setHasAttemptedVerification] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const verifyEmailToken = async () => {
            const params = new URLSearchParams(location.search);
            const token = params.get('token');
            const pendingEmail = sessionStorage.getItem('pendingVerification');

            // Prevent multiple verification attempts
            if (hasAttemptedVerification) {
                return;
            }

            console.log('Email Verification State:', {
                hasToken: !!token,
                tokenPreview: token ? `${token.substring(0, 10)}...` : 'none',
                pendingEmail: pendingEmail || 'none',
                currentPath: location.pathname,
                fullUrl: location.pathname + location.search
            });

            if (!token) {
                if (!pendingEmail) {
                    console.log('No token or pending email - redirecting to register');
                    navigate('/register');
                    return;
                }
                console.log('No token but found pending email - showing awaiting screen');
                setVerificationStatus('awaiting');
                return;
            }

            try {
                setHasAttemptedVerification(true);
                console.log('Starting email verification with token:', token);
                setVerificationStatus('verifying');

                const response = await fetch('https://swami-app-ad729ba29873.herokuapp.com/api/auth/verify-email', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        verification_token: token
                    })
                });

                console.log('Verification request details:', {
                    token: token,
                    status: response.status,
                });

                // Handle the response
                const responseData = await response.json().catch(() => ({}));
                console.log('Response data:', responseData);

                if (!response.ok) {
                    console.error('Error response:', responseData);
                    throw new Error(responseData.detail || 'Verification failed. Please try again.');
                }

                setVerificationStatus('success');
                setMessage(responseData.message || 'Email verified successfully!');
                sessionStorage.removeItem('pendingVerification');
            } catch (err) {
                console.error('Verification error:', err);
                setVerificationStatus('error');
                setError(typeof err === 'string' ? err : err.message || 'An error occurred during verification. Please try again.');
            }
        };

        verifyEmailToken();
    }, [location, navigate, hasAttemptedVerification]);

    const handleResendVerification = async () => {
        const pendingEmail = sessionStorage.getItem('pendingVerification');
        
        if (!pendingEmail) {
            setError('No email address found for verification.');
            return;
        }

        try {
            console.log('Attempting to resend verification email');
            setIsResending(true);
            setError(null);
            
            const token = localStorage.getItem('accessToken');
            if (!token) {
                throw new Error('No authentication token found. Please log in again.');
            }

            const response = await fetch('https://swami-app-ad729ba29873.herokuapp.com/api/auth/resend-verification', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });

            const responseData = await response.json();
            console.log('Resend verification response:', responseData);

            if (!response.ok) {
                throw new Error(responseData.detail || 'Failed to resend verification email.');
            }

            setMessage('Verification email sent successfully! Please check your inbox.');
            setVerificationStatus('awaiting');
        } catch (err) {
            console.error('Resend verification error:', err);
            setError(err.message || 'Failed to resend verification email. Please try again.');
        } finally {
            setIsResending(false);
        }
    };

    const renderVerificationContent = () => {
        const pendingEmail = sessionStorage.getItem('pendingVerification');

        switch (verificationStatus) {
            case 'initializing':
            case 'verifying':
                return (
                    <div className="text-center space-y-4">
                        <Loader2 className="mx-auto h-12 w-12 text-gray-400 animate-spin" />
                        <p className="text-gray-600">
                            {verificationStatus === 'initializing' 
                                ? 'Loading verification page...' 
                                : 'Verifying your email...'}
                        </p>
                    </div>
                );

            case 'success':
                return (
                    <div className="text-center space-y-4">
                        <CheckCircle className="mx-auto h-12 w-12 text-green-500" />
                        <div>
                            <h3 className="text-xl font-semibold text-gray-900">
                                Verification Successful
                            </h3>
                            <p className="mt-2 text-gray-600">
                                Your email has been verified successfully!
                            </p>
                        </div>
                        <button
                            onClick={() => navigate('/login')}
                            className="w-full flex items-center justify-center gap-2 py-2 px-4 bg-black text-white rounded-md hover:bg-gray-800 transition-colors"
                        >
                            Continue to Login
                            <ArrowRight className="h-4 w-4" />
                        </button>
                    </div>
                );

            case 'error':
                return (
                    <div className="text-center space-y-4">
                        <XCircle className="mx-auto h-12 w-12 text-red-500" />
                        <Alert className="bg-red-50 border-red-200">
                            <AlertDescription className="text-red-800">
                                {error}
                            </AlertDescription>
                        </Alert>
                        {pendingEmail && (
                            <button
                                onClick={handleResendVerification}
                                disabled={isResending}
                                className="w-full flex items-center justify-center gap-2 py-2 px-4 bg-black text-white rounded-md hover:bg-gray-800 transition-colors disabled:bg-gray-400"
                            >
                                {isResending ? (
                                    <>
                                        <Loader2 className="h-4 w-4 animate-spin" />
                                        Sending...
                                    </>
                                ) : 'Resend Verification Email'}
                            </button>
                        )}
                        <button
                            onClick={() => navigate('/login')}
                            className="w-full py-2 px-4 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50 transition-colors"
                        >
                            Return to Login
                        </button>
                    </div>
                );

            case 'awaiting':
                return (
                    <div className="text-center space-y-4">
                        <Mail className="mx-auto h-12 w-12 text-gray-400" />
                        <div>
                            <h3 className="text-xl font-semibold text-gray-900">
                                Check Your Email
                            </h3>
                            <p className="text-gray-600 mt-2">
                                We've sent a verification link to:
                            </p>
                            <p className="font-medium text-gray-900 break-all">
                                {pendingEmail}
                            </p>
                        </div>
                        
                        <div className="text-sm text-gray-500">
                            <p>Please check your email and click the verification link.</p>
                            <p className="mt-1">(Don't forget to check your spam folder)</p>
                        </div>

                        <button
                            onClick={handleResendVerification}
                            disabled={isResending}
                            className="w-full flex items-center justify-center gap-2 py-2 px-4 bg-black text-white rounded-md hover:bg-gray-800 transition-colors disabled:bg-gray-400"
                        >
                            {isResending ? (
                                <>
                                    <Loader2 className="h-4 w-4 animate-spin" />
                                    Sending...
                                </>
                            ) : 'Resend Verification Email'}
                        </button>
                    </div>
                );

            default:
                return (
                    <div className="text-center space-y-4">
                        <Loader2 className="mx-auto h-12 w-12 text-gray-400 animate-spin" />
                        <p className="text-gray-600">Loading verification page...</p>
                    </div>
                );
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
            <Card className="max-w-md w-full">
                <CardHeader>
                    <h2 className="text-center text-2xl font-bold text-gray-900">
                        Email Verification
                    </h2>
                </CardHeader>
                <CardContent>
                    {message && (
                        <Alert className="mb-6 bg-blue-50 border-blue-200">
                            <AlertDescription className="text-blue-800">
                                {message}
                            </AlertDescription>
                        </Alert>
                    )}
                    {renderVerificationContent()}
                </CardContent>
            </Card>
        </div>
    );
};

export default EmailVerification;