import { useState, useEffect, useRef, useCallback } from 'react';
import {
    fetchStartedGroups,
    fetchMemberGroups,
    fetchPopularGroups,
    fetchPredictions,
    fetchPredictionComments,
    getCurrentUser,
    fetchGames,
    fetchOverallLeaderboard,
    fetchTrendingSwamis,
    fetchHotStreakLeaderboard
} from '../api';

// Type Definitions
interface User {
    id: number;
    username: string;
    email: string;
    created_at: string;
    subscription_tier?: string;
    total_points?: number;
    win_rate?: number;
    available_balance?: number;
}

interface Prediction {
    id: number;
    content: string;
    created_at: string;
    group_id: number;
    user_id: number;
    result?: string;
    comments?: Comment[];
    game?: string;
    game_id?: number;
}

interface Comment {
    id: number;
    content: string;
    created_at: string;
    user_id: number;
    prediction_id: number;
    likes: number;
    dislikes: number;
    insightful: number;
    funny: number;
    user_reaction: string | null;
}

interface BaseGroup {
    id: number;
    name: string;
    description: string;
    swami_id: number;
    member_count: number;
}

interface Group extends BaseGroup {
    created_at: string;
}

interface PopularGroup extends BaseGroup {
    price: number;
    created_at: Date;
}

interface Membership {
    group: Group;
    joined_at: string;
}

interface Game {
    id: number;
    sport_id: number;
    home_team: string;
    away_team: string;
    event_date: string;
    status: string;
    period?: number;
    is_game_over?: boolean;
}

interface CacheEntry<T> {
    data: T;
    timestamp: number;
    staleAt: number;
}

interface ApiOptions {
    cacheKey?: string | null;
    cacheDuration?: number;
    staleWhileRevalidate?: boolean;
    deduplicate?: boolean;
    batchSize?: number;
    skip?: boolean;
}

interface ApiState<T> {
    data: T | null;
    loading: boolean;
    error: Error | null;
}

// Cache and Request Management
export const apiCache = new Map<string, CacheEntry<any>>();
const pendingRequests = new Map<string, Promise<any>>();

// Utility Functions
const isCacheValid = (entry: CacheEntry<any>): boolean => {
    return Date.now() < entry.staleAt;
};

const clearApiCache = (cacheKey?: string) => {
    if (cacheKey) {
        apiCache.delete(cacheKey);
    } else {
        apiCache.clear();
    }
};

const invalidateOldCache = (maxAge: number = 60000) => {
    const now = Date.now();
    Array.from(apiCache.keys()).forEach(key => {
        const entry = apiCache.get(key);
        if (entry && now - entry.timestamp > maxAge) {
            apiCache.delete(key);
        }
    });
};

// Main API Hook
export function useApi<T>(
    apiFunction: () => Promise<T>,
    dependencies: any[] = [],
    options: ApiOptions = {}
): ApiState<T> & { refresh: () => Promise<void> } {
    const {
        cacheKey = null,
        cacheDuration = 60000,
        staleWhileRevalidate = true,
        deduplicate = true,
        batchSize = 10,
        skip = false
    } = options;

    const [state, setState] = useState<ApiState<T>>({
        data: null,
        loading: !skip,
        error: null
    });

    const abortControllerRef = useRef<AbortController | null>(null);
    const isMountedRef = useRef(true);

    const fetchWithCache = useCallback(async () => {
        if (!isMountedRef.current || skip) return;

        abortControllerRef.current = new AbortController();
        const { signal } = abortControllerRef.current;

        try {
            // Check cache
            if (cacheKey) {
                const cached = apiCache.get(cacheKey);
                if (cached) {
                    setState(prev => ({ ...prev, data: cached.data }));
                    
                    if (isCacheValid(cached) && !staleWhileRevalidate) {
                        setState(prev => ({ ...prev, loading: false }));
                        return;
                    }
                }
            }

            // Handle duplicate requests
            if (deduplicate && cacheKey && pendingRequests.has(cacheKey)) {
                const data = await pendingRequests.get(cacheKey);
                if (!signal.aborted && isMountedRef.current) {
                    setState({ data, loading: false, error: null });
                }
                return;
            }

            // Make API call
            const promise = apiFunction();
            if (deduplicate && cacheKey) {
                pendingRequests.set(cacheKey, promise);
            }

            const response = await promise;

            if (!signal.aborted && isMountedRef.current) {
                if (cacheKey) {
                    apiCache.set(cacheKey, {
                        data: response,
                        timestamp: Date.now(),
                        staleAt: Date.now() + cacheDuration
                    });
                }
                setState({ data: response, loading: false, error: null });
            }
        } catch (err) {
            if (!signal.aborted && isMountedRef.current) {
                setState(prev => ({
                    ...prev,
                    error: err instanceof Error ? err : new Error('An error occurred'),
                    loading: false
                }));
            }
        } finally {
            if (cacheKey) {
                pendingRequests.delete(cacheKey);
            }
        }
    }, [apiFunction, cacheKey, cacheDuration, staleWhileRevalidate, deduplicate, skip]);

    useEffect(() => {
        isMountedRef.current = true;
        fetchWithCache();

        return () => {
            isMountedRef.current = false;
            abortControllerRef.current?.abort();
        };
    }, dependencies);

    const refresh = useCallback(() => fetchWithCache(), [fetchWithCache]);

    return { ...state, refresh };
}

// Optimized Predictions Hook
export function usePredictions(groupIds: number[] = []) {
    return useApi<Prediction[]>(
        async () => {
            const allPredictions: Prediction[] = [];
            const seenPredictionIds = new Set<number>();
            const BATCH_SIZE = 5;

            // Process groups in batches
            for (let i = 0; i < groupIds.length; i += BATCH_SIZE) {
                const batch = groupIds.slice(i, i + BATCH_SIZE);
                
                // Fetch predictions for batch in parallel
                const batchResults = await Promise.all(
                    batch.map(groupId => fetchPredictions(groupId))
                );

                // Filter unique predictions
                const uniquePredictions = batchResults.flat().filter(pred => {
                    if (seenPredictionIds.has(pred.id)) return false;
                    seenPredictionIds.add(pred.id);
                    return true;
                });

                // Fetch comments in parallel for batch
                const predictionsWithComments = await Promise.all(
                    uniquePredictions.map(async prediction => ({
                        ...prediction,
                        comments: await fetchPredictionComments(prediction.id)
                    }))
                );

                allPredictions.push(...predictionsWithComments);
            }

            return allPredictions.sort((a, b) => 
                new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
            );
        },
        [JSON.stringify(groupIds)],
        {
            cacheKey: `predictions-${JSON.stringify(groupIds)}`,
            cacheDuration: 30000,
            staleWhileRevalidate: true
        }
    );
}

// Optimized Groups Hook
export function useGroups() {
    const initialFetch = useCallback(async () => {
        const [started, member, popular] = await Promise.all([
            fetchStartedGroups(),
            fetchMemberGroups(),
            fetchPopularGroups()
        ]);

        const normalizedPopular: Group[] = popular.map(group => ({
            ...group,
            created_at: group.created_at.toISOString()
        }));

        return {
            startedGroups: started,
            memberGroups: member,
            popularGroups: normalizedPopular
        };
    }, []);

    const { data, loading, error } = useApi(
        initialFetch,
        [],
        {
            cacheKey: 'all-groups',
            cacheDuration: 300000, // 5 minutes
            staleWhileRevalidate: true
        }
    );

    return {
        startedGroups: data?.startedGroups || [],
        memberGroups: data?.memberGroups || [],
        popularGroups: data?.popularGroups || [],
        loading,
        error
    };
}

// Dashboard Data Hook
export function useDashboardData() {
    return useApi(
        async () => {
            const [user, games, leaderboard] = await Promise.all([
                getCurrentUser(),
                fetchGames(),
                fetchOverallLeaderboard()
            ]);

            return { user, games, leaderboard };
        },
        [],
        {
            cacheKey: 'dashboard-initial',
            cacheDuration: 300000, // 5 minutes
            staleWhileRevalidate: true
        }
    );
}

// Leaderboard Hook
export function useLeaderboard(type: 'overall' | 'trending' | 'hot-streak') {
    const leaderboardFunctions = {
        overall: fetchOverallLeaderboard,
        trending: fetchTrendingSwamis,
        'hot-streak': fetchHotStreakLeaderboard
    };

    return useApi(
        leaderboardFunctions[type],
        [type],
        {
            cacheKey: `leaderboard-${type}`,
            cacheDuration: 60000, // 1 minute
            staleWhileRevalidate: true
        }
    );
}

export type {
    User,
    Prediction,
    Comment,
    Group,
    PopularGroup,
    Membership,
    Game,
    ApiOptions,
    ApiState
};