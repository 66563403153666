import React from 'react';
import { Calendar, Clock, Eye } from 'lucide-react';
import PropTypes from 'prop-types';

const GameCard = ({ game, onPickGame, onViewPrediction, existingPrediction }) => {
    if (!game) return null;

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric'
        });
    };

    const formatTime = (dateString) => {
        if (!dateString) return '';
        
        try {
            // Parse the ISO string
            const date = new Date(dateString);
            
            // Get timezone offset from the ISO string
            const timezoneParts = dateString.match(/([-+]\d{2}):?(\d{2})$/);
            if (!timezoneParts) {
                console.warn('No timezone info in date string:', dateString);
                return date.toLocaleTimeString('en-US', {
                    hour: 'numeric',
                    minute: '2-digit'
                });
            }
            
            // Get the hours offset from the server (e.g., -07 for MT)
            const serverOffset = parseInt(timezoneParts[1], 10);
            
            // Get user's local offset in hours
            const localOffset = -new Date().getTimezoneOffset() / 60;
            
            // Calculate time difference between server and local
            const diff = localOffset - serverOffset;
            
            // Adjust the date object by the difference
            const adjustedDate = new Date(date.getTime() - diff * 60 * 60 * 1000);
            
            // Format the time
            return adjustedDate.toLocaleTimeString('en-US', {
                hour: 'numeric',
                minute: '2-digit'
            });
        } catch (error) {
            console.error('Error formatting time:', error);
            return dateString;
        }
    };

    const getBestOdds = (odds, type) => {
        if (!odds || odds.length === 0) return null;

        switch (type) {
            case 'home':
                return odds.reduce((best, current) => {
                    return (current.moneyline_home > best.moneyline_home) ? current : best;
                });
            case 'away':
                return odds.reduce((best, current) => {
                    return (current.moneyline_away > best.moneyline_away) ? current : best;
                });
            default:
                return null;
        }
    };

    const formatOdds = (odds) => {
        if (Math.abs(odds) < 0.01) {
            return "Pick 'Em";
        }
        if (odds > 0) return `+${odds}`;
        return odds.toString();
    };

    const getGameStatus = () => {
        const status = game.status?.toLowerCase() || '';

        if (status === 'completed' ||
            status === 'final' ||
            status.includes('complete') ||
            game.is_game_over === true) {
            return {
                style: 'bg-gray-100 text-gray-800',
                text: 'FINAL',
                isFinal: true
            };
        }

        if (status === 'in_progress' ||
            status === 'in-progress' ||
            game.period > 0) {
            return {
                style: 'bg-green-100 text-green-800',
                text: 'IN PROGRESS',
                isFinal: false
            };
        }

        const gameDate = new Date(game.event_date);
        const now = new Date();
        if (gameDate > now ||
            status === 'scheduled' ||
            status === 'upcoming') {
            return {
                style: 'bg-blue-100 text-blue-800',
                text: 'UPCOMING',
                isFinal: false
            };
        }

        return {
            style: 'bg-gray-100 text-gray-800',
            text: status.toUpperCase() || 'SCHEDULED',
            isFinal: false
        };
    };

    const handlePickGame = (e) => {
        e.preventDefault();
        onPickGame?.(game);
    };

    const handleViewPrediction = (e) => {
        e.preventDefault();
        onViewPrediction?.(existingPrediction);
    };

    const getActionButton = () => {
        const gameStatus = getGameStatus();

        if (gameStatus.isFinal) {
            return null; // No button for final games
        }

        if (existingPrediction) {
            return (
                <button
                    onClick={handleViewPrediction}
                    type="button"
                    className="px-4 py-2 text-sm font-medium text-blue-600 bg-blue-50 rounded-md hover:bg-blue-100 transition-colors inline-flex items-center gap-2"
                >
                    <Eye className="h-4 w-4" />
                    View My Pick
                </button>
            );
        }

        return (
            <button
                onClick={handlePickGame}
                type="button"
                className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 transition-colors"
            >
                Pick This Game
            </button>
        );
    };

    const validSpread = (spread) => Math.abs(spread) >= 0.5;

    const homeOdds = getBestOdds(game.odds, 'home');
    const awayOdds = getBestOdds(game.odds, 'away');
    const gameStatus = getGameStatus();

    return (
        <div className="bg-white rounded-lg shadow-sm border border-gray-200 hover:shadow-md transition-shadow">
            <div className="p-4">
                <div className="flex justify-between items-center mb-3">
                    <div className="flex items-center space-x-2">
                        <Calendar className="h-4 w-4 text-gray-500" />
                        <span className="text-sm text-gray-600">{formatDate(game.event_date)}</span>
                    </div>
                    <div className="flex items-center space-x-2">
                        <Clock className="h-4 w-4 text-gray-500" />
                        <span className="text-sm text-gray-600">{formatTime(game.event_date)}</span>
                    </div>
                </div>

                <div className="flex justify-between items-center mb-4">
                    <div className="space-y-3 flex-1">
                        <div className="flex justify-between items-center">
                            <span className="font-semibold">{game.home_team}</span>
                            {homeOdds && (
                                <span className="text-sm font-medium text-gray-600">
                                    {formatOdds(homeOdds.moneyline_home)}
                                </span>
                            )}
                        </div>
                        <div className="flex justify-between items-center">
                            <span className="font-semibold">{game.away_team}</span>
                            {awayOdds && (
                                <span className="text-sm font-medium text-gray-600">
                                    {formatOdds(awayOdds.moneyline_away)}
                                </span>
                            )}
                        </div>
                    </div>
                </div>

                {/* Display Spread */}
                {game.odds && game.odds.length > 0 && validSpread(game.odds[0].point_spread_home) && validSpread(game.odds[0].point_spread_away) && (
                    <div className="text-center border-t pt-3">
                        <div className="text-sm text-gray-500">
                            Spread: Home {game.odds[0].point_spread_home || 'N/A'} | Away {game.odds[0].point_spread_away || 'N/A'}
                        </div>
                    </div>
                )}

                {game.home_score !== undefined && game.away_score !== undefined && (
                    <div className="text-center border-t pt-3">
                        <div className="flex justify-center items-center space-x-4">
                            <span className="text-xl font-bold">{game.home_score}</span>
                            <span className="text-sm text-gray-500">-</span>
                            <span className="text-xl font-bold">{game.away_score}</span>
                        </div>
                    </div>
                )}

                <div className="mt-3 flex justify-between items-center">
                    <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${gameStatus.style}`}>
                        {gameStatus.text}
                    </span>
                    {getActionButton()}
                </div>
            </div>
        </div>
    );
};

GameCard.propTypes = {
    game: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        home_team: PropTypes.string,
        away_team: PropTypes.string,
        event_date: PropTypes.string,
        status: PropTypes.string,
        period: PropTypes.number,
        is_game_over: PropTypes.bool,
        home_score: PropTypes.number,
        away_score: PropTypes.number,
        odds: PropTypes.arrayOf(
            PropTypes.shape({
                moneyline_home: PropTypes.number,
                moneyline_away: PropTypes.number,
                point_spread_home: PropTypes.number,
                point_spread_away: PropTypes.number
            })
        )
    }).isRequired,
    onPickGame: PropTypes.func,
    onViewPrediction: PropTypes.func,
    existingPrediction: PropTypes.shape({
        id: PropTypes.number,
        game_id: PropTypes.number,
        pick: PropTypes.string,
        odds: PropTypes.number,
        result: PropTypes.string,
        potential_points: PropTypes.number,
        earned_points: PropTypes.number
    })
};

export default GameCard;