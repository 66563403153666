// src/api.js
import axios from 'axios';

const API_BASE_URL = 'https://swami-app-ad729ba29873.herokuapp.com';
const WS_BASE_URL = 'wss://swami-app-ad729ba29873.herokuapp.com';

// Create axios instance with base configuration
const api = axios.create({
  baseURL: API_BASE_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  }
});

// Request interceptor
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor
// Add to your api.js interceptor
// Response interceptor
api.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error('Axios interceptor caught an error:', error);
    
    const status = error.response?.status;
    const originalRequest = error.config;
    
    // Only handle these redirects for API routes that require authentication
    const requiresAuth = !originalRequest.url.startsWith('/api/public/') && 
                        !originalRequest.url.includes('/auth/token') &&
                        !originalRequest.url.includes('/auth/register');

    if (status === 403 && error.response?.data?.detail === "Email verification required") {
      // Redirect to verification page if email isn't verified
      if (!window.location.pathname.includes('/verify')) {
        window.location.href = '/verify';
      }
    } else if (status === 401 && !originalRequest._retry && requiresAuth) {
      // Only handle 401s for authenticated routes
      originalRequest._retry = true;
      localStorage.removeItem('accessToken');
      
      // Instead of redirecting, just remove the token and let the app handle it
      // The ProtectedRoute component will handle the redirect if needed
      return Promise.reject(error);
    }

    return Promise.reject(error);
  }
);

export const createGroupWebSocket = (groupId, onMessage, onError) =>
  createWebSocketConnection('groups', groupId, onMessage, onError);

// WebSocket Functions
export const createWebSocketConnection = (context, id, onMessage, onError) => {
  if (!id) {
    console.error(`Invalid ID for WebSocket connection: ${context} context received undefined ID.`);
    return;
  }
  
  const token = localStorage.getItem('accessToken');
  if (!token) {
    console.error('No authentication token found');
    return;
  }

  console.log('Creating WebSocket connection...');
  console.log('Context:', context);
  console.log('ID:', id);
  console.log('Token:', token.substring(0, 20) + '...');
  
  const wsUrl = `${WS_BASE_URL}/api/comments/ws/groups/${id}/comments?token=${token}`;
  console.log('WebSocket URL:', wsUrl);

  const ws = new WebSocket(wsUrl);

  ws.onopen = () => {
    console.log('WebSocket connection opened successfully');
  };

  ws.onmessage = (event) => {
    try {
      const data = JSON.parse(event.data);
      console.log('Received WebSocket message:', data);
      onMessage(data);
    } catch (error) {
      console.error('Error parsing WebSocket message:', error);
      if (onError) onError(error);
    }
  };

  ws.onerror = (error) => {
    console.error('WebSocket error:', error);
    if (onError) onError(error);
  };

  ws.onclose = (event) => {
    console.log('WebSocket closed:', {
      code: event.code,
      reason: event.reason,
      wasClean: event.wasClean
    });
  };

  return {
    socket: ws,
    close: () => {
      if (ws.readyState === WebSocket.OPEN) {
        ws.close();
      }
    },
    isOpen: () => ws.readyState === WebSocket.OPEN
  };
};

export const createPredictionWebSocket = (predictionId, onMessage, onError) => 
  createWebSocketConnection('predictions', predictionId, onMessage, onError);

// Authentication APIs
export const loginUser = async (username, password) => {
  try {
    const params = new URLSearchParams();
    params.append('username', username);
    params.append('password', password);

    const response = await api.post('/api/auth/token', params, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    return response.data.access_token;
  } catch (error) {
    console.error('Error logging in:', error);
    throw error.response?.data || new Error('Login failed');
  }
};

export const registerUser = async (email, username, password, zipCode, tosAccepted, promoCode) => {
  try {
      console.log('Attempting registration with data:', {
          email,
          username,
          zipCode,
          tosAccepted,
          promoCode: promoCode ? '[PRESENT]' : '[NOT PROVIDED]'
      });

      const response = await api.post('/api/auth/register', {
          email,
          username,
          password,
          zip_code: zipCode,
          tos_accepted: tosAccepted,
          promo_code: promoCode || undefined
      });

      console.log('Registration response:', {
          status: response.status,
          statusText: response.statusText,
          data: {
              ...response.data,
              access_token: response.data.access_token ? '[PRESENT]' : '[NOT PRESENT]'
          }
      });

      if (response.data.access_token) {
          // Store the token if it's present
          localStorage.setItem('accessToken', response.data.access_token);
      }

      // Structure the success response
      return {
          success: true,
          access_token: response.data.access_token,
          message: response.data.message || "Registration successful! Please check your email to verify your account.",
          promo_applied: response.data.promo_applied || false,
          promo_amount: response.data.promo_amount || 0
      };

  } catch (error) {
      console.error('Registration error:', {
          message: error.message,
          response: {
              status: error.response?.status,
              data: error.response?.data,
              headers: error.response?.headers
          }
      });

      // Handle specific error cases
      if (error.response?.status === 409) {
          if (error.response.data?.detail?.includes('email')) {
              throw new Error('This email is already registered');
          }
          if (error.response.data?.detail?.includes('username')) {
              throw new Error('This username is already taken');
          }
      }

      if (error.response?.status === 422) {
          const validationError = error.response.data?.detail?.[0];
          if (validationError) {
              throw new Error(validationError.msg || 'Invalid input data');
          }
      }

      // Handle network errors
      if (error.message === 'Network Error') {
          throw new Error('Unable to connect to the server. Please check your internet connection.');
      }

      // If we have a specific error message from the server, use it
      if (error.response?.data?.detail) {
          throw new Error(error.response.data.detail);
      }

      // If we have a specific error message in the response data, use it
      if (error.response?.data?.message) {
          throw new Error(error.response.data.message);
      }

      // Fallback error message
      throw new Error('Registration failed. Please try again later.');
  }
};

export const getCurrentUser = async () => {
  try {
    // First get user profile
    const userResponse = await api.get('/api/auth/me');
    
    // Then get current stats
    const statsResponse = await api.get('/api/statistics/my/current-stats');
    
    console.log('User Response:', userResponse.data);
    console.log('Stats Response:', statsResponse.data);
    
    // Determine subscription tier based on admin and swami status
    const subscriptionTier = userResponse.data.is_admin ? 'Admin' :
                           userResponse.data.is_swami ? 'Swami' : 'Free';
    
    // Combine user data with statistics and subscription tier
    const userData = {
      ...userResponse.data,
      total_points: statsResponse.data.total_points,
      win_rate: statsResponse.data.win_rate,
      total_predictions: statsResponse.data.total_predictions,
      current_streak: statsResponse.data.current_streak,
      subscription_tier: subscriptionTier  // Add the subscription tier
    };
    
    console.log('Combined user data:', userData);
    return userData;
  } catch (error) {
    console.error('Error in getCurrentUser:', error);
    if (error.response?.status === 404) {
      return null;
    }
    throw error;
  }
};

export const updateUserProfile = async (userData) => {
  try {
    const response = await api.put('/api/users/profile', userData);
    return response.data;
  } catch (error) {
    console.error('Error updating user profile:', error);
    throw error;
  }
};

export const changePassword = async (currentPassword, newPassword) => {
  try {
    const response = await api.post('/api/auth/change-password', {
      current_password: currentPassword,
      new_password: newPassword
    });
    return response.data;
  } catch (error) {
    console.error('Error changing password:', error);
    throw error;
  }
};

// Groups APIs
export const fetchUserGroups = async () => {
    try {
        const response = await api.get('/api/groups');
        return response.data;
    } catch (error) {
        console.error('Error fetching user groups:', error);
        // Return empty array for 404
        if (error.response?.status === 404) {
            return [];
        }
        throw error;
    }
};

export const fetchGroupDetails = async (groupId) => {
  try {
    const response = await api.get(`/api/groups/${groupId}/details`);
    return response.data;
  } catch (error) {
    if (error.response?.status === 404) {
      console.warn('Group not found.');
      return null;
    }
    throw error;
  }
};

export const createGroup = async (groupData) => {
  try {
    const response = await api.post('/api/groups/', groupData);
    return response.data;
  } catch (error) {
    console.error('Error creating group:', error);
    throw error;
  }
};

export const deleteGroup = async (groupId) => {
  try {
    const response = await api.delete(`/api/groups/${groupId}`);
    return response.data || true;
  } catch (error) {
    console.error('Error deleting group:', error);
    if (error.message?.includes('Unexpected token')) {
      return true;
    }
    throw error;
  }
};

export const joinGroup = async (groupId) => {
  try {
    const response = await api.post(`/api/groups/${groupId}/join`);
    return response.data;
  } catch (error) {
    console.error('Error joining group:', error);
    throw error;
  }
};

export const fetchStartedGroups = async () => {
  try {
    const response = await api.get('/api/groups/my-groups');
    return response.data;
  } catch (error) {
    console.error('Error fetching started groups:', error);
    throw error;
  }
};

export const fetchMemberGroups = async () => {
  try {
    const response = await api.get('/api/memberships/my-memberships');
    return response.data.map(membership => ({
      ...membership.group,
      member_count: membership.group.member_count || 0,
      created_at: membership.joined_at
    }));
  } catch (error) {
    console.error('Error fetching member groups:', error);
    throw error;
  }
};
export const fetchPopularGroups = async (skip = 0, limit = 10) => {
    try {
      const response = await api.get(`/api/groups/popular?skip=${skip}&limit=${limit}`);
      if (!Array.isArray(response.data)) {
        console.error('Invalid response format from popular groups endpoint');
        return [];
      }
      
      return response.data.map(group => ({
        id: Number(group.id),
        name: String(group.name),
        description: String(group.description),
        swami_id: Number(group.swami_id),
        member_count: Number(group.member_count) || 0,
        price: Number(group.price),
        created_at: new Date(group.created_at)
      }));
    } catch (error) {
      console.error('Error fetching popular groups:', error);
      return [];
    }
  };
  
  // Predictions APIs
  export const fetchPredictions = async (groupId, includeSync = false) => {
    try {
      if (includeSync) {
        console.log('Running pre-fetch sync for group:', groupId);
        const syncResult = await syncGameStatuses();
        console.log('Sync completed before fetch:', syncResult);
      }
  
      const response = await api.get(`/api/predictions/group/${groupId}`);
      
      // Debug log the predictions
      const predictions = response.data;
      console.log('Fetched predictions:', predictions);
      
      // Log any pending predictions that should be complete
      const pendingPredictions = predictions.filter(p => 
        p.result === 'PENDING' && new Date(p.game_time) < new Date()
      );
      
      if (pendingPredictions.length > 0) {
        console.warn('Found pending predictions for past games:', pendingPredictions);
      }
  
      return predictions;
    } catch (error) {
      console.error('Error fetching predictions:', error);
      if (error.response?.status === 404) {
        return [];
      }
      throw error;
    }
  };
  
  // In api.js

  export const createPrediction = async (predictionData) => {
  try {
    console.log('Creating prediction:', predictionData);
    
    // Get current live points
    const livePoints = await getLivePoints(
      predictionData.game_id, 
      predictionData.bet_type
    );

    // Prepare prediction request
    const requestData = {
      ...predictionData,
      timestamp: new Date().toISOString(),
      current_odds_state: livePoints
    };

    // Create prediction
    const response = await api.post('/api/predictions/create', requestData);
    console.log('Prediction created:', response.data);

    // Create announcement comment
    const commentContent = formatPredictionComment(predictionData);
    console.log('Formatted prediction comment:', commentContent);

    try {
      const comment = await createGroupComment(predictionData.group_id, commentContent);
      console.log('Prediction announcement created:', comment);

      // Verify the comment was created
      const verifyComments = await fetchGroupComments(predictionData.group_id);
      console.log('Verified group comments after creation:', verifyComments);
    } catch (commentError) {
      console.error('Failed to create prediction announcement:', commentError);
    }

    return response.data;
  } catch (error) {
    console.error('Error creating prediction:', error);
    throw error;
  }
};
  
  const formatPredictionComment = (predictionData) => {
    const { pick, bet_type, game, total_number, comments, potential_points } = predictionData;
    
    let predictionText;
    switch (bet_type) {
      case 'TOTAL':
        predictionText = `🎯 New Prediction: I'm taking ${pick} ${total_number} in ${game}`;
        break;
      case 'SPREAD':
        predictionText = `🎯 New Prediction: I'm taking ${pick} in ${game}`;
        break;
      case 'MONEYLINE':
        predictionText = `🎯 New Prediction: I'm taking ${pick} in ${game}`;
        break;
      default:
        predictionText = `🎯 New Prediction: ${pick} in ${game}`;
    }
  
    if (potential_points) {
      predictionText += ` (${potential_points.toFixed(2)} points)`;
    }
  
    if (comments?.trim()) {
      predictionText += `\n\nReasoning: ${comments.trim()}`;
    }
  
    return predictionText;
  };
  
  export const fetchPredictionResults = async (groupId, dateRange) => {
    try {
      const response = await api.get(`/api/predictions/group/${groupId}/results`, {
        params: dateRange
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching prediction results:', error);
      throw error;
    }
  };
  
  // Comments APIs
  export const fetchComments = async (context, id) => {
    if (!id) {
        console.error(`fetchComments called with an undefined ID for context: ${context}`);
        return [];
    }

    try {
        const response = await api.get(`/api/comments/${context}/${id}`);
        return response.data;
    } catch (error) {
        if (error.response?.status === 403) {
            console.error('Not authorized to view comments');
        } else if (error.response?.status === 404) {
            console.error(`${context} not found`);
        } else {
            console.error('Error fetching comments:', error);
        }
        return [];
    }
};
  
  export const createComment = async (context, id, content, parentId = null) => {
    try {
      const response = await api.post(`/api/comments/${context}/${id}`, {
        content,
        parent_id: parentId
      });
      return response.data;
    } catch (error) {
      console.error('Error creating comment:', error);
      throw error;
    }
  };
  
  export const reactToComment = async (context, id, commentId, reactionType) => {
    try {
        console.log(`Sending reaction: ${reactionType} to comment ${commentId} in ${context} ${id}`);
        const response = await api.post(
            `/api/comments/${context}/${id}/comments/${commentId}/react`,
            { reaction_type: reactionType }
        );
        console.log('Reaction response:', response.data);
        return response.data;
    } catch (error) {
        // Add more detailed error logging
        console.error('Error reacting to comment:', error.response?.data || error.message);
        throw error;
    }
};
  
  export const deleteComment = async (context, id, commentId) => {
    try {
      const response = await api.delete(
        `/api/comments/${context}/${id}/comments/${commentId}`
      );
      return response.data;
    } catch (error) {
      console.error('Error deleting comment:', error);
      throw error;
    }
  };
  
  // Comment Helper Functions
  export const fetchPredictionComments = async (predictionId) => fetchComments('predictions', predictionId);
  export const createPredictionComment = async (predictionId, content) => {
    return createComment('predictions', predictionId, content);
};

export const createGroupComment = async (groupId, content) => {
  try {
    console.log('Creating group comment:', { groupId, content });
    
    if (!content?.trim()) {
      throw new Error('Comment content cannot be empty');
    }

    const response = await api.post(`/api/comments/groups/${groupId}`, {
      content: content.trim()
    }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
      }
    });

    console.log('Comment creation response:', {
      status: response.status,
      data: response.data
    });

    return response.data;
  } catch (error) {
    console.error('Error creating group comment:', {
      message: error.message,
      response: error.response?.data,
      status: error.response?.status
    });
    throw error;
  }
};

// Update the fetchGroupComments function
export const fetchGroupComments = async (groupId) => {
  try {
    console.log('Fetching comments for group:', groupId);
    
    // Add explicit error handling for missing groupId
    if (!groupId) {
      console.error('No group ID provided to fetchGroupComments');
      return [];
    }
    
    // Log the full request details
    console.log('Request URL:', `${API_BASE_URL}/api/comments/groups/${groupId}/comments`);
    console.log('Access Token:', localStorage.getItem('accessToken')?.substring(0, 20) + '...');

    // Make the request with explicit headers
    const response = await api.get(`/api/comments/groups/${groupId}/comments`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
        'Accept': 'application/json'
      }
    });

    // Log the raw response
    console.log('Comments API Response:', {
      status: response.status,
      statusText: response.statusText,
      headers: response.headers,
      data: response.data
    });

    // Verify data structure
    if (!response.data) {
      console.warn('No data received from comments endpoint');
      return [];
    }

    // Convert dates and sort
    const processedComments = Array.isArray(response.data) ? response.data.map(comment => ({
      ...comment,
      created_at: new Date(comment.created_at),
      likes: comment.likes || 0,
      dislikes: comment.dislikes || 0,
      insightful: comment.insightful || 0,
      funny: comment.funny || 0
    })) : [];

    const sortedComments = processedComments.sort(
      (a, b) => b.created_at - a.created_at
    );

    console.log('Processed and sorted comments:', sortedComments);
    return sortedComments;
  } catch (error) {
    console.error('Error fetching group comments:', {
      message: error.message,
      response: error.response?.data,
      status: error.response?.status
    });
    
    if (error.response?.status === 403) {
      console.error('Access denied to group comments');
    }
    
    return [];
  }
};

export const reactToGroupComment = async (groupId, commentId, reactionType) => {
  try {
    console.log('Reacting to comment:', { groupId, commentId, reactionType });
    const response = await api.post(
      `/api/comments/groups/${groupId}/comments/${commentId}/react`,
      { reaction_type: reactionType }
    );
    console.log('Reaction response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error reacting to comment:', error);
    throw error;
  }
};

  export const reactToPredictionComment = async (predictionId, commentId, reactionType) => {
    return reactToComment('predictions', predictionId, commentId, reactionType);
};
export const deleteGroupComment = async (groupId, commentId) => {
  try {
    console.log('Deleting comment:', { groupId, commentId });
    const response = await api.delete(
      `/api/comments/groups/${groupId}/comments/${commentId}`
    );
    console.log('Delete response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error deleting comment:', error);
    throw error;
  }
};

  export const deletePredictionComment = async (predictionId, commentId) => 
    deleteComment('predictions', predictionId, commentId);
  
  // Statistics APIs
  export const fetchUserStats = async () => {
    try {
      const response = await api.get('/api/statistics/user');
      return response.data;
    } catch (error) {
      console.error('Error fetching user statistics:', error);
      throw error;
    }
  };
  
  // Leaderboard APIs
  export const fetchOverallLeaderboard = async (days = 30, minPredictions = 1) => {
    try {
      const response = await api.get(`/api/leaderboard/overall?days=${days}&min_predictions=${minPredictions}`);
      
      // Add debug logging
      console.log('Leaderboard response:', response.data);
      
      // Ensure proper number formatting for each entry
      return response.data.map(entry => ({
        ...entry,
        total_points: entry.total_points ? parseFloat(entry.total_points) : 0,
        win_rate: entry.win_rate ? parseFloat(entry.win_rate) : 0,
        current_streak: parseInt(entry.current_streak || 0),
      }));
    } catch (error) {
      console.error('Error fetching overall leaderboard:', error);
      return [];
    }
  };
  
  export const fetchSportLeaderboard = async (sport, days = 30, minPredictions = 3) => {
    try {
      const response = await api.get(
        `/api/leaderboard/sport/${sport}?days=${days}&min_predictions=${minPredictions}`
      );
      return response.data;
    } catch (error) {
      console.error('Error fetching sport leaderboard:', error);
      return [];
    }
  };
  
  export const fetchTrendingSwamis = async (days = 7, minPredictions = 3) => {
    try {
      const response = await api.get(
        `/api/leaderboard/trending?days=${days}&min_predictions=${minPredictions}`
      );
      return response.data;
    } catch (error) {
      console.error('Error fetching trending swamis:', error);
      return [];
    }
  };
  
  export const fetchHotStreakLeaderboard = async (minStreak = 3) => {
    try {
      const response = await api.get(`/api/leaderboard/hot-streak?min_streak=${minStreak}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching hot streak leaderboard:', error);
      return [];
    }
  };
  
  // Search functions
  export const searchGroups = async (query) => {
    try {
      const response = await api.get('/api/groups/search', {
        params: { q: query }
      });
      return response.data;
    } catch (error) {
      console.error('Error searching groups:', error);
      return [];
    }
  };
  
  export const searchUsers = async (query) => {
    try {
      const response = await api.get('/api/users/search', {
        params: { q: query }
      });
      return response.data;
    } catch (error) {
      console.error('Error searching users:', error);
      return [];
    }
  };
  
  // Helper function to handle WebSocket message parsing
  export const handleWebSocketMessage = (data, handlers) => {
    try {
      console.log('Processing WebSocket message:', data);
      
      if (!data.type) {
        console.warn('WebSocket message missing type:', data);
        return;
      }
  
      if (handlers[data.type]) {
        console.log(`Handling ${data.type} message:`, data);
        handlers[data.type](data);
      } else {
        console.warn('No handler for WebSocket message type:', data.type);
      }
    } catch (error) {
      console.error('Error handling WebSocket message:', error);
    }
  };
  
  export default api;

  // Add to api.js
  // src/api.js - Add these new functions

  // src/api.js - Updated fetchGames function

  export const fetchGames = async (includeSync = false) => {
    try {
      const token = localStorage.getItem('accessToken');
      
      // If no token, use public endpoint
      if (!token) {
        return fetchPublicGames();
      }
  
      console.log("Fetching games from the backend-defined date range...");
  
      // Initial API request for games
      let response = await api.get('/api/games/');
      const { date_start, date_end, games } = response.data;
  
      console.log(`Backend date range: ${date_start} to ${date_end}`);
      let fetchedGames = games;
  
      // Sync statuses if requested and authenticated
      if (includeSync) {
        try {
          console.log("Syncing game statuses...");
          await syncGameStatuses();
  
          // Fetch updated games after syncing statuses
          response = await api.get('/api/games/');
          fetchedGames = response.data.games;
        } catch (syncError) {
          console.error('Error during status sync:', syncError);
        }
      }
  
      // Sort games by event date (ascending)
      fetchedGames.sort((a, b) => new Date(a.event_date) - new Date(b.event_date));
  
      console.log(`Fetched ${fetchedGames.length} games within range.`);
      return fetchedGames;
    } catch (error) {
      console.error('Error fetching games:', error);
      // Try public endpoint as fallback
      return fetchPublicGames();
    }
  };

export const updateGames = async () => {
  try {
    const response = await api.post('/api/games/update');
    console.log('Games update started:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error updating games:', error);
    throw error;
  }
};

export const fetchGameById = async (gameId) => {
  try {
    const response = await api.get(`/api/games/${gameId}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching game ${gameId}:`, error);
    throw error;
  }
};

export const syncGameStatuses = async () => {
  try {
    console.log('Starting game status sync...');
    const response = await api.post('/api/games/sync-status');
    console.log('Sync response:', response.data);
    
    // Check if we got the expected response format
    if (response.data.games_processed === 0) {
      console.warn('No games were processed during sync');
    }
    
    // Log any errors from the sync
    if (response.data.errors && response.data.errors.length > 0) {
      console.error('Sync errors:', response.data.errors);
    }
    
    return response.data;
  } catch (error) {
    console.error('Error in syncGameStatuses:', error.response?.data || error.message);
    throw error;
  }
};

export const checkGameStatusConsistency = async () => {
  try {
    const response = await api.get('/api/games/status-check');
    console.log('Game status consistency check:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error checking game status consistency:', error);
    throw error;
  }
};

export const checkPredictionStatus = async (predictionId) => {
  try {
    const response = await api.get(`/api/games/status-check`);
    console.log('Status check for prediction:', predictionId, response.data);
    return response.data;
  } catch (error) {
    console.error('Error checking prediction status:', error);
    throw error;
  }
};

// Tournament APIs
export const fetchTournaments = async () => {
  try {
    const response = await api.get('/api/tournaments?include_completed=true');
    return response.data.map(tournament => ({
      ...tournament,
      entryCount: tournament.entry_count || 0,
      potentialWinnings: (
        (tournament.entry_count * tournament.entry_fee * 0.6) +
        (tournament.pot_boost || 0)
      ).toFixed(2),
      has_completed_picks: tournament.has_completed_picks || false,
      has_entered: tournament.has_entered || false,
      picks_locked: tournament.has_completed_picks || false
    }));
  } catch (error) {
    console.error('Error fetching tournaments:', error);
    return [];
  }
};

export const fetchTournamentDetails = async (tournamentId) => {
  try {
    const response = await api.get(`/api/tournaments/${tournamentId}`);
    const tournament = response.data;

    // Check if user has completed all required picks based on tournament data
    const hasCompletedPicks = tournament.has_completed_picks || false;
    const userPicks = tournament.user_picks || {};

    return {
      ...tournament,
      entryCount: tournament.entry_count || 0,
      potentialWinnings: (tournament.entry_count * tournament.entry_fee * 0.6).toFixed(2),
      has_completed_picks: hasCompletedPicks,
      picks_locked: hasCompletedPicks,
      user_picks: userPicks,
      has_entered: tournament.has_entered || false
    };
  } catch (error) {
    console.error('Error fetching tournament details:', error);
    throw error;
  }
};

export const enterTournament = async (tournamentId) => {
  try {
    const response = await api.post(`/api/tournaments/${tournamentId}/entry`);
    return response.data;
  } catch (error) {
    console.error('Error entering tournament:', error);
    throw error;
  }
};

export const submitTournamentPicks = async (tournamentId, picks) => {
  console.log(`Submitting picks for tournament ${tournamentId}:`, picks);
  try {
    const response = await api.post(`/api/tournaments/${tournamentId}/picks`, picks);
    console.log('Response from backend:', response.data);
    return {
      ...response.data,
      success: true
    };
  } catch (error) {
    console.error('Error submitting tournament picks:', error);
    throw error;
  }
};


export const fetchTournamentLeaderboard = async (tournamentId) => {
  try {
    const response = await api.get(`/api/tournaments/${tournamentId}/leaderboard`);
    return response.data.map(entry => ({
      ...entry,
      points: parseFloat(entry.points || 0),
      correct_picks: parseInt(entry.correct_picks || 0),
      total_picks: parseInt(entry.total_picks || 0),
    }));
  } catch (error) {
    console.error('Error fetching tournament leaderboard:', error);
    return [];
  }
};

export const fetchUserTournamentHistory = async () => {
  try {
    const response = await api.get('/api/tournaments/history');
    return response.data.map(entry => ({
      ...entry,
      points: parseFloat(entry.points || 0),
      correct_picks: parseInt(entry.correct_picks || 0),
      total_picks: parseInt(entry.total_picks || 0),
      entry_date: new Date(entry.entry_date),
    }));
  } catch (error) {
    console.error('Error fetching tournament history:', error);
    return [];
  }
};

export const getLivePoints = async (gameId, betType) => {
  try {
    const response = await api.get(`/api/games/${gameId}/live-points`, {
      params: { bet_type: betType }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching live points:', error);
    // Return default points structure
    return {
      home_team: { points: 15.95 },
      away_team: { points: 15.95 },
      odds_updated_at: null
    };
  }
};

// Optional: Helper function for polling live points
export const startLivePointsPolling = (gameId, betType, onUpdate, interval = 30000) => {
  const pollPoints = async () => {
    try {
      const points = await getLivePoints(gameId, betType);
      onUpdate(points);
    } catch (error) {
      console.error('Error polling live points:', error);
    }
  };

  // Initial fetch
  pollPoints();

  // Set up polling interval
  const timerId = setInterval(pollPoints, interval);

  // Return cleanup function
  return () => clearInterval(timerId);
};

export const createTournament = async (tournamentData) => {
  try {
    const response = await api.post('/api/tournaments/admin/create', tournamentData);
    return response.data;
  } catch (error) {
    console.error('Error creating tournament:', error);
    throw error;
  }
};

export const verifyGroupAccess = async (groupId) => {
  try {
    const response = await api.get(`/api/comments/debug/group-access/${groupId}`);
    console.log('Group access verification:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error verifying group access:', error);
    return null;
  }
};

export const debugApi = async (groupId) => {
  try {
    console.group('API Debug Information');
    console.log('Testing API endpoints for group:', groupId);
    
    // Test connection basics
    console.log('Base URL:', API_BASE_URL);
    console.log('Has Token:', !!localStorage.getItem('accessToken'));
    
    // Test group access
    const accessResponse = await api.get(`/api/comments/debug/group-access/${groupId}`);
    console.log('Access check:', accessResponse.data);

    // Test raw comments endpoint
    const rawResponse = await api.get(`/api/comments/debug/raw-comments/${groupId}`);
    console.log('Raw comments:', rawResponse.data);

    // Test regular comments endpoint
    const commentsResponse = await api.get(`/api/comments/groups/${groupId}`);
    console.log('Comments endpoint:', commentsResponse.data);

    console.groupEnd();
    return {
      success: true,
      access: accessResponse.data,
      rawComments: rawResponse.data,
      comments: commentsResponse.data
    };
  } catch (error) {
    console.error('API Debug Error:', {
      message: error.message,
      response: error.response?.data,
      status: error.response?.status
    });
    console.groupEnd();
    return {
      success: false,
      error: error.message,
      details: error.response?.data
    };
  }
};

export const debugComments = async (groupId) => {
  try {
    console.log('Running comments debug for group:', groupId);
    
    // Test raw comments endpoint
    const rawResponse = await api.get(`/api/comments/debug/raw-comments/${groupId}`);
    console.log('Raw comments:', rawResponse.data);

    // Test membership endpoint
    const membershipResponse = await api.get(`/api/comments/debug/membership/${groupId}`);
    console.log('Membership status:', membershipResponse.data);

    // Test regular comments endpoint
    const commentsResponse = await api.get(`/api/comments/groups/${groupId}`);
    console.log('Regular comments endpoint:', commentsResponse.data);

    return {
      raw: rawResponse.data,
      membership: membershipResponse.data,
      processed: commentsResponse.data
    };
  } catch (error) {
    console.error('Debug error:', error);
    return {
      error: error.message,
      response: error.response?.data
    };
  }
};

export const debugGroupAccess = async (groupId) => {
  try {
    const response = await api.get(`/api/comments/debug/group-access/${groupId}`);
    console.log('Group access check:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error checking group access:', error);
    return { error: error.message };
  }
};

export const verifyWebSocketConnection = async (groupId) => {
  try {
    const token = localStorage.getItem('accessToken');
    if (!token) {
      return { error: 'No auth token found' };
    }

    const wsUrl = `${WS_BASE_URL}/api/comments/ws/groups/${groupId}/comments?token=${token}`;
    console.log('Testing WebSocket URL:', wsUrl);

    return new Promise((resolve) => {
      const ws = new WebSocket(wsUrl);
      
      ws.onopen = () => {
        ws.close();
        resolve({ success: true, message: 'WebSocket connection successful' });
      };

      ws.onerror = (error) => {
        resolve({ error: 'WebSocket connection failed', details: error });
      };

      // Timeout after 5 seconds
      setTimeout(() => {
        if (ws.readyState !== WebSocket.OPEN) {
          ws.close();
          resolve({ error: 'WebSocket connection timeout' });
        }
      }, 5000);
    });
  } catch (error) {
    return { error: error.message };
  }
};

// Modified (JavaScript)
export const fetchCurrentTOS = async () => {
  try {
    const response = await api.get('/api/tos/current');
    return response.data;
  } catch (error) {
    console.error('Error fetching current TOS:', error);
    throw error;
  }
};

// Fetch a specific version of the Terms of Service
export const fetchTOSByVersion = async (version) => {
  try {
    const response = await api.get(`/api/tos/versions/${version}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching TOS version ${version}:`, error);
    throw error;
  }
};

export const createDeposit = async (amount) => {
  try {
    const response = await api.post('/api/payments/deposit', { amount });
    return response.data;
  } catch (error) {
    console.error('Create deposit error:', error.response?.data || error.message);
    throw error;
  }
};

// In api.js - add these with your other API functions

export const verifyEmail = async (token) => {
  try {
      // Create form data
      const formData = new URLSearchParams();
      formData.append('verification_token', token);

      const response = await api.post('/api/auth/verify-email', formData, {
          headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
          },
      });
      
      return {
          success: true,
          message: response.data.message || 'Email verified successfully'
      };
  } catch (error) {
      console.error('Verification error:', error);
      throw error;
  }
};

export const resendVerificationEmail = async () => {
  try {
      const response = await api.post('/api/auth/resend-verification');
      return {
          success: true,
          message: response.data.message || 'Verification email sent successfully'
      };
  } catch (error) {
      console.error('Resend verification error:', error);
      throw new Error(
          error.response?.data?.detail || 
          error.message || 
          'Failed to resend verification email'
      );
  }
};

// Add these payment functions with your other API functions

export const requestWithdrawal = async (amount) => {
  try {
    const response = await api.post('/api/payments/withdraw', { amount });
    return response.data;
  } catch (error) {
    console.error('Withdrawal request error:', error.response?.data || error.message);
    throw error;
  }
};

export const verifyPayoutWebhook = async (token) => {
  try {
    const response = await api.post('/api/payments/payout-webhook', { token });
    return response.data;
  } catch (error) {
    console.error('Payout webhook verification error:', error.response?.data || error.message);
    throw error;
  }
};

export const fetchPublicGames = async () => {
  try {
    console.log("Fetching public games...");
    
    // Create a new axios instance without auth headers for this specific request
    const publicApi = axios.create({
      baseURL: API_BASE_URL,
      headers: {
        'Content-Type': 'application/json',
      }
    });

    const response = await publicApi.get('/api/public/games');
    return response.data.games || [];
  } catch (error) {
    console.error('Error fetching public games:', error);
    return []; // Return empty array on error
  }
};

