import React from "react";
import { cn } from "../../lib/utils";
import { Button } from "./button";

const Tabs = ({ defaultValue, children, className }) => {
  const [activeTab, setActiveTab] = React.useState(defaultValue);

  const childrenArray = React.Children.toArray(children);
  const triggers = childrenArray.filter(child => child.type === TabsList);
  const contents = childrenArray.filter(child => child.type === TabsContent);

  const activeContent = contents.find(
    content => content.props.value === activeTab
  );

  return (
    <div className={cn("w-full", className)}>
      {React.cloneElement(triggers[0], { activeTab, setActiveTab })}
      {activeContent}
    </div>
  );
};

const TabsList = ({ children, activeTab, setActiveTab, className }) => {
  return (
    <div className={cn("flex space-x-2 border-b", className)}>
      {React.Children.map(children, child => {
        if (child.type === TabsTrigger) {
          return React.cloneElement(child, { 
            active: activeTab === child.props.value,
            onClick: () => setActiveTab(child.props.value)
          });
        }
        return child;
      })}
    </div>
  );
};

const TabsTrigger = ({ children, value, active, onClick, className }) => {
  return (
    <Button
      variant="ghost"
      onClick={onClick}
      className={cn(
        "border-b-2 rounded-none",
        active ? "border-blue-500 text-blue-600" : "border-transparent",
        className
      )}
    >
      {children}
    </Button>
  );
};

const TabsContent = ({ children, value }) => {
  return (
    <div className="mt-4">
      {children}
    </div>
  );
};

export { Tabs, TabsList, TabsTrigger, TabsContent };