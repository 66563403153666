import React, { useState } from 'react';
import { ArrowLeft, Mail, LogOut, Heart, Book } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { Card } from './ui/card';
import { Separator } from './ui/separator';
import TermsOfService from './TermsOfService';

const Profile = () => {
    const navigate = useNavigate();
    const [showTOS, setShowTOS] = useState(false);

    const handleLogout = () => {
        // First remove the token
        localStorage.removeItem('accessToken');
        // Then navigate to landing page
        navigate('/', { replace: true });
    };

    const handleContact = () => {
        window.location.href = 'mailto:contact@swami.com';
    };

    const handleDonateClick = () => {
        window.open('https://www.paypal.com/donate?business=swamiapponline@gmail.com&currency_code=USD', '_blank');
    };

    return (
        <div className="min-h-screen bg-white">
            {/* Header */}
            <div className="bg-white p-4 text-black border-b">
                <div className="max-w-7xl mx-auto">
                    <button
                        onClick={() => window.history.back()}
                        className="flex items-center text-gray-600 hover:text-gray-900"
                    >
                        <ArrowLeft className="h-5 w-5 mr-2" />
                        Back to Dashboard
                    </button>
                </div>
            </div>

            <div className="p-8">
                <Card className="max-w-2xl mx-auto p-6">
                    <h1 className="text-2xl font-bold text-gray-900 mb-8">Profile Settings</h1>

                    <div className="space-y-6">
                        {/* Donate Section */}
                        <div className="space-y-4">
                            <h2 className="text-lg font-semibold text-gray-900">Support Swami</h2>
                            <button
                                onClick={handleDonateClick}
                                className="flex items-center justify-center px-4 py-3 bg-black text-white font-semibold rounded-lg hover:bg-gray-800 transition-colors w-full"
                            >
                                <Heart className="h-5 w-5 mr-2" />
                                Donate with PayPal
                            </button>
                        </div>

                        <Separator />

                        {/* Support Section */}
                        <div className="space-y-4">
                            <h2 className="text-lg font-semibold text-gray-900">Help & Support</h2>
                            <div className="grid gap-3">
                                <button
                                    onClick={handleContact}
                                    className="flex items-center justify-center px-4 py-3 bg-white text-black border-2 border-black rounded-lg hover:bg-gray-50 transition-colors w-full"
                                >
                                    <Mail className="h-5 w-5 mr-2" />
                                    Contact Support
                                </button>
                                <button
                                    onClick={() => setShowTOS(true)}
                                    className="flex items-center justify-center px-4 py-3 bg-white text-black border-2 border-black rounded-lg hover:bg-gray-50 transition-colors w-full"
                                >
                                    <Book className="h-5 w-5 mr-2" />
                                    Terms of Service
                                </button>
                            </div>
                        </div>

                        <Separator />

                        {/* Account Section */}
                        <div className="space-y-4">
                            <h2 className="text-lg font-semibold text-gray-900">Account</h2>
                            <button
                                onClick={handleLogout}
                                className="flex items-center justify-center px-4 py-3 bg-red-600 text-white rounded-lg hover:bg-red-700 transition-colors w-full"
                            >
                                <LogOut className="h-5 w-5 mr-2" />
                                Logout
                            </button>
                        </div>
                    </div>
                </Card>
            </div>

            <TermsOfService
                isOpen={showTOS}
                onClose={() => setShowTOS(false)}
                showAccept={false}
            />
        </div>
    );
};

export default Profile;