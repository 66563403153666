import { useNavigate } from 'react-router-dom';
import { createPrediction, fetchMemberGroups, getLivePoints } from '../api';
import { X } from 'lucide-react';
import React, { useState, useEffect } from 'react';

const BET_TYPES = {
    SPREAD: 'SPREAD',
    MONEYLINE: 'MONEYLINE',
    TOTAL: 'TOTAL'
};

const NewPredictionForm = ({ preselectedGame, onClose }) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [groups, setGroups] = useState([]);
    const [selectedTeam, setSelectedTeam] = useState(null);
    const [livePoints, setLivePoints] = useState(null);
    const [availableBetTypes, setAvailableBetTypes] = useState(Object.values(BET_TYPES));

    const initialFormData = {
        group_id: '',
        game: preselectedGame ? `${preselectedGame.home_team} vs ${preselectedGame.away_team}` : '',
        pick: '',
        comments: '',
        game_time: preselectedGame
            ? new Date(preselectedGame.event_date).toISOString().slice(0, 16)
            : new Date(Date.now() + 3600000).toISOString().slice(0, 16),
        bet_type: BET_TYPES.SPREAD,
        total_number: ''
    };

    const [formData, setFormData] = useState(initialFormData);

    const homeTeam = preselectedGame?.home_team || formData.game.split(' vs ')[0];
    const awayTeam = preselectedGame?.away_team || formData.game.split(' vs ')[1];

    const validSpread = (spread) => Math.abs(spread) >= 0.5;

    const getSpreadDisplay = (team) => {
        if (!preselectedGame?.odds?.[0]) return '';
        const spread = team === homeTeam ? 
            preselectedGame.odds[0].point_spread_home : 
            preselectedGame.odds[0].point_spread_away;
        if (!validSpread(spread)) return '';
        return spread > 0 ? `+${spread}` : spread.toString();
    };

    useEffect(() => {
        if (!preselectedGame?.game_id || !formData.bet_type) return;
    
        const fetchLivePointsAndCheckSpread = async () => {
            try {
                const points = await getLivePoints(preselectedGame.game_id, formData.bet_type);
                setLivePoints(points);
                
                // Check spread availability from odds array
                if (preselectedGame.odds?.[0]) {
                    const homeSpread = preselectedGame.odds[0].point_spread_home;
                    const awaySpread = preselectedGame.odds[0].point_spread_away;
                    
                    const hasValidSpreads = validSpread(homeSpread) && validSpread(awaySpread);
                    
                    const newBetTypes = Object.values(BET_TYPES).filter(type => {
                        if (!hasValidSpreads && type === BET_TYPES.SPREAD) {
                            return false;
                        }
                        return true;
                    });
                    
                    setAvailableBetTypes(newBetTypes);
                    
                    if (!hasValidSpreads && formData.bet_type === BET_TYPES.SPREAD) {
                        setFormData(prev => ({
                            ...prev,
                            bet_type: BET_TYPES.MONEYLINE
                        }));
                        setSelectedTeam(null);
                    }
                }
            } catch (error) {
                console.error('Error fetching live points:', error);
            }
        };
    
        fetchLivePointsAndCheckSpread();
        const interval = setInterval(fetchLivePointsAndCheckSpread, 30000);
        return () => clearInterval(interval);
    }, [preselectedGame?.game_id, formData.bet_type]);

    useEffect(() => {
        const loadGroups = async () => {
            setLoading(true);
            try {
                const groupsData = await fetchMemberGroups();
                setGroups(groupsData);
                if (groupsData.length > 0) {
                    setFormData((prev) => ({ ...prev, group_id: groupsData[0].id }));
                }
            } catch (err) {
                setErrorMessage('Failed to load groups. Please try again.');
            } finally {
                setLoading(false);
            }
        };

        loadGroups();
    }, []);

    const getCurrentPoints = () => {
        if (!livePoints || !selectedTeam) return null;

        if (formData.bet_type === BET_TYPES.TOTAL) {
            return selectedTeam === 'over'
                ? livePoints.over?.points
                : livePoints.under?.points;
        }

        return selectedTeam === homeTeam
            ? livePoints.home_team?.points
            : livePoints.away_team?.points;
    };

    const handleTeamSelect = (team) => {
        setSelectedTeam(team);
        let pickText = team;

        if (formData.bet_type === BET_TYPES.TOTAL) {
            pickText = formData.total_number
                ? `${team === 'over' ? 'Over' : 'Under'} ${formData.total_number}`
                : 'Select total';
        } else if (formData.bet_type === BET_TYPES.SPREAD) {
            const spread = getSpreadDisplay(team);
            pickText = `${team} ${spread}`;
        }

        setFormData((prev) => ({
            ...prev,
            pick: pickText,
        }));
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => {
            const newData = { ...prev, [name]: value };
            if (name === "bet_type") {
                setSelectedTeam(null);
            }
            return newData;
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (submitting || !selectedTeam) return;

        setErrorMessage('');
        setSubmitting(true);

        try {
            const currentPoints = getCurrentPoints();
            if (!currentPoints) {
                throw new Error('Unable to get current points. Please try again.');
            }

            const predictionData = {
                group_id: Number(formData.group_id),
                game: formData.game,
                pick: formData.pick,
                reasoning: formData.comments || '',
                game_time: formData.game_time,
                bet_type: formData.bet_type,
                total_number: formData.total_number ? Number(formData.total_number) : null,
                potential_points: currentPoints,
                odds_timestamp: livePoints?.odds_updated_at
            };

            const response = await createPrediction(predictionData);
            if (response) {
                if (onClose) onClose();
                navigate('/dashboard', {
                    replace: true,
                    state: {
                        activeTab: 'pick-games',
                        message: 'Prediction created successfully!',
                    },
                });
            }
        } catch (err) {
            let message = 'Failed to create prediction. Please try again.';
            if (err.response?.data) {
                const errorData = err.response.data;
                message = typeof errorData === 'string' ? errorData :
                    errorData.detail ? (Array.isArray(errorData.detail)
                        ? errorData.detail.map(e => e.msg || e.message).join('. ')
                        : errorData.detail) :
                    errorData.message || message;
            }
            setErrorMessage(message);
        } finally {
            setSubmitting(false);
        }
    };

    if (loading && groups.length === 0) {
        return (
            <div className="min-h-screen flex items-center justify-center bg-gray-50">
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500" />
            </div>
        );
    }

    const containerClass = preselectedGame ? "p-4" : "min-h-screen bg-gray-50 p-4 md:p-8";

    return (
        <div className={containerClass}>
            <div className="max-w-2xl mx-auto">
                <div className="mb-6 flex items-center justify-between">
                    <h1 className="text-2xl font-bold">New Prediction</h1>
                    <button
                        onClick={onClose || (() => navigate('/dashboard'))}
                        className="text-gray-600 hover:text-gray-900"
                    >
                        <X className="h-6 w-6" />
                    </button>
                </div>

                {errorMessage && (
                    <div className="mb-6 p-4 bg-red-50 border border-red-200 text-red-600 rounded-lg">
                        {errorMessage}
                    </div>
                )}

                <form onSubmit={handleSubmit} className="space-y-6 bg-white rounded-lg shadow-sm p-6">
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-2">Group</label>
                        <select
                            name="group_id"
                            value={formData.group_id}
                            onChange={handleChange}
                            required
                            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                        >
                            <option value="">Select a group</option>
                            {groups.map(group => (
                                <option key={group.id} value={group.id}>{group.name}</option>
                            ))}
                        </select>
                    </div>

                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-2">Game</label>
                        <input
                            type="text"
                            name="game"
                            value={formData.game}
                            onChange={handleChange}
                            required
                            placeholder="e.g., Lakers vs Warriors"
                            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                            readOnly={!!preselectedGame}
                        />
                    </div>

                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-2">Game Time</label>
                        <input
                            type="datetime-local"
                            name="game_time"
                            value={formData.game_time}
                            onChange={handleChange}
                            required
                            min={new Date().toISOString().slice(0, 16)}
                            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                            readOnly={!!preselectedGame}
                        />
                    </div>

                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-2">Bet Type</label>
                        <select
                            name="bet_type"
                            value={formData.bet_type}
                            onChange={handleChange}
                            required
                            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                        >
                            {availableBetTypes.map((betType) => (
                                <option key={betType} value={betType}>
                                    {betType.charAt(0) + betType.slice(1).toLowerCase()}
                                </option>
                            ))}
                        </select>
                    </div>

                    {formData.bet_type === BET_TYPES.TOTAL && (
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-2">Total Number</label>
                            <input
                                type="number"
                                name="total_number"
                                value={formData.total_number}
                                onChange={handleChange}
                                required={formData.bet_type === BET_TYPES.TOTAL}
                                step="0.5"
                                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                        </div>
                    )}

                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-2">Pick Your Team</label>
                        <div className="grid grid-cols-2 gap-4">
                            {formData.bet_type === BET_TYPES.TOTAL ? (
                                <>
                                    <button
                                        type="button"
                                        onClick={() => handleTeamSelect('over')}
                                        className={`px-4 py-3 text-sm font-medium rounded-md border ${
                                            selectedTeam === 'over'
                                                ? 'bg-blue-600 text-white border-blue-600'
                                                : 'bg-white text-gray-700 border-gray-300 hover:bg-gray-50'
                                        }`}
                                    >
                                        <div>Over {formData.total_number}</div>
                                        {livePoints?.over?.points && (
                                            <div className="text-sm mt-1">
                                                Points: {livePoints.over.points.toFixed(2)}
                                            </div>
                                        )}
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => handleTeamSelect('under')}
                                        className={`px-4 py-3 text-sm font-medium rounded-md border ${
                                            selectedTeam === 'under'
                                                ? 'bg-blue-600 text-white border-blue-600'
                                                : 'bg-white text-gray-700 border-gray-300 hover:bg-gray-50'
                                        }`}
                                    >
                                        <div>Under {formData.total_number}</div>
                                        {livePoints?.under?.points && (
                                            <div className="text-sm mt-1">
                                                Points: {livePoints.under.points.toFixed(2)}
                                            </div>
                                        )}
                                    </button>
                                </>
                            ) : (
                                <>
                                    <button
                                        type="button"
                                        onClick={() => handleTeamSelect(homeTeam)}
                                        className={`px-4 py-3 text-sm font-medium rounded-md border ${
                                            selectedTeam === homeTeam
                                                ? 'bg-blue-600 text-white border-blue-600'
                                                : 'bg-white text-gray-700 border-gray-300 hover:bg-gray-50'
                                        }`}
                                    >
                                        <div>{homeTeam}</div>
                                        {/* Show both spread and points for spread bets */}
                                        {formData.bet_type === BET_TYPES.SPREAD ? (
                                            <>
                                                <div className="text-sm mt-1">
                                                    Spread: {getSpreadDisplay(homeTeam)}
                                                </div>
                                                {livePoints?.home_team?.points && (
                                                    <div className="text-sm mt-1">
                                                        Points: {livePoints.home_team.points.toFixed(2)}
                                                    </div>
                                                )}
                                            </>
                                        ) : (
                                            // Show just points for moneyline
                                            livePoints?.home_team?.points && (
                                                <div className="text-sm mt-1">
                                                    Points: {livePoints.home_team.points.toFixed(2)}
                                                </div>
                                            )
                                        )}
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => handleTeamSelect(awayTeam)}
                                        className={`px-4 py-3 text-sm font-medium rounded-md border ${
                                            selectedTeam === awayTeam
                                                ? 'bg-blue-600 text-white border-blue-600'
                                                : 'bg-white text-gray-700 border-gray-300 hover:bg-gray-50'
                                        }`}
                                    >
                                        <div>{awayTeam}</div>
                                        {/* Show both spread and points for spread bets */}
                                        {formData.bet_type === BET_TYPES.SPREAD ? (
                                            <>
                                                <div className="text-sm mt-1">
                                                    Spread: {getSpreadDisplay(awayTeam)}
                                                </div>
                                                {livePoints?.away_team?.points && (
                                                    <div className="text-sm mt-1">
                                                        Points: {livePoints.away_team.points.toFixed(2)}
                                                    </div>
                                                )}
                                            </>
                                        ) : (
                                            // Show just points for moneyline
                                            livePoints?.away_team?.points && (
                                                <div className="text-sm mt-1">
                                                    Points: {livePoints.away_team.points.toFixed(2)}
                                                </div>
                                            )
                                        )}
                                    </button>
                                </>
                            )}
                        </div>
                        {formData.pick && (
                            <div className="mt-2 text-sm text-gray-600">
                                Selected: {formData.pick}
                            </div>
                        )}
                    </div>

                    {livePoints?.odds_updated_at && (
                        <div className="p-4 bg-gray-50 rounded-lg">
                            <div className="text-xs text-gray-500">
                                Last odds update: {new Date(livePoints.odds_updated_at).toLocaleTimeString()}
                            </div>
                        </div>
                    )}

                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-2">Comments</label>
                        <textarea
                            name="comments"
                            value={formData.comments}
                            onChange={handleChange}
                            rows="4"
                            placeholder="Add your comments about this prediction (optional)..."
                            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>

                    <div className="flex justify-end space-x-4">
                        <button
                            type="button"
                            onClick={onClose || (() => navigate('/dashboard'))}
                            className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            disabled={submitting || !selectedTeam}
                            className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 disabled:opacity-50"
                        >
                            {submitting ? 'Creating...' : 'Create Prediction'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default NewPredictionForm;