import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Trophy, Users, DollarSign, TrendingUp, ChevronLeft, ChevronRight } from 'lucide-react';
import { Card } from './ui/card';

const TrendingScroller = ({ tournaments = [], games = [] }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate();

  // Auto-scroll every 5 seconds
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prev) => (prev + 1) % items.length);
    }, 5000);

    return () => clearInterval(timer);
  }, []);

  // Prepare the items for the scroller
  const items = [
    // Popular tournaments (by participant count)
    ...tournaments
      .filter(t => !t.completed && new Date(t.start_date) > new Date())
      .sort((a, b) => (b.participant_count || 0) - (a.participant_count || 0))
      .slice(0, 2)
      .map(tournament => ({
        type: 'popular-tournament',
        icon: Users,
        title: tournament.name,
        description: `${tournament.participant_count || 0} Swamis joined`,
        action: () => navigate(`/tournaments/${tournament.id}`),
        highlight: tournament.participant_count
      })),

    // High prize tournaments
    ...tournaments
      .filter(t => !t.completed && new Date(t.start_date) > new Date())
      .sort((a, b) => b.prize_amount - a.prize_amount)
      .slice(0, 2)
      .map(tournament => ({
        type: 'prize-tournament',
        icon: Trophy,
        title: tournament.name,
        description: `$${tournament.prize_amount.toFixed(2)} Prize Pool`,
        action: () => navigate(`/tournaments/${tournament.id}`),
        highlight: `$${tournament.prize_amount.toFixed(2)}`
      })),

    // Trending games (most picks)
    ...games
      .filter(g => !g.is_game_over && g.pick_count > 0)
      .sort((a, b) => (b.pick_count || 0) - (a.pick_count || 0))
      .slice(0, 2)
      .map(game => ({
        type: 'trending-game',
        icon: TrendingUp,
        title: `${game.away_team} vs ${game.home_team}`,
        description: `${game.pick_count || 0} picks made`,
        action: () => navigate(`/games/${game.id}`),
        highlight: game.pick_count
      }))
  ];

  const handlePrev = () => {
    setCurrentIndex((prev) => (prev - 1 + items.length) % items.length);
  };

  const handleNext = () => {
    setCurrentIndex((prev) => (prev + 1) % items.length);
  };

  if (items.length === 0) return null;

  return (
    <div className="w-full bg-gray-50 border-b">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-2">
        <div className="flex items-center justify-between">
          <button
            onClick={handlePrev}
            className="p-1 rounded-full hover:bg-gray-200 transition-colors"
            aria-label="Previous item"
          >
            <ChevronLeft className="w-5 h-5" />
          </button>

          <div className="flex-1 mx-4">
            {items.map((item, idx) => (
              <div
                key={idx}
                className={`transition-opacity duration-500 ${
                  idx === currentIndex ? 'block opacity-100' : 'hidden opacity-0'
                }`}
              >
                <button
                  onClick={item.action}
                  className="w-full text-left hover:bg-gray-100 rounded-lg p-2 transition-colors"
                >
                  <div className="flex items-center gap-3">
                    <div className={`
                      rounded-full p-2
                      ${item.type === 'popular-tournament' ? 'bg-purple-100 text-purple-600' : ''}
                      ${item.type === 'prize-tournament' ? 'bg-yellow-100 text-yellow-600' : ''}
                      ${item.type === 'trending-game' ? 'bg-blue-100 text-blue-600' : ''}
                    `}>
                      <item.icon className="w-4 h-4" />
                    </div>
                    <div className="flex-1">
                      <p className="font-medium text-sm">{item.title}</p>
                      <p className="text-sm text-gray-600">{item.description}</p>
                    </div>
                    <div className={`
                      px-3 py-1 rounded-full text-sm font-medium
                      ${item.type === 'popular-tournament' ? 'bg-purple-100 text-purple-600' : ''}
                      ${item.type === 'prize-tournament' ? 'bg-yellow-100 text-yellow-600' : ''}
                      ${item.type === 'trending-game' ? 'bg-blue-100 text-blue-600' : ''}
                    `}>
                      {item.highlight}
                    </div>
                  </div>
                </button>
              </div>
            ))}
          </div>

          <button
            onClick={handleNext}
            className="p-1 rounded-full hover:bg-gray-200 transition-colors"
            aria-label="Next item"
          >
            <ChevronRight className="w-5 h-5" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default TrendingScroller;