import React from 'react';

export const Alert = ({ variant = 'info', children }) => {
    const baseStyle = 'p-4 rounded border';
    const variantStyles = {
        info: 'bg-blue-50 border-blue-300 text-blue-700',
        warning: 'bg-yellow-50 border-yellow-300 text-yellow-700',
        error: 'bg-red-50 border-red-300 text-red-700',
        success: 'bg-green-50 border-green-300 text-green-700',
    };

    return (
        <div className={`${baseStyle} ${variantStyles[variant] || ''}`}>
            {children}
        </div>
    );
};

export const AlertTitle = ({ children }) => (
    <div className="font-bold mb-1">{children}</div>
);

export const AlertDescription = ({ children }) => (
    <div className="text-sm">{children}</div>
);
