import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { 
  ArrowLeft, 
  Trophy, 
  Award, 
  TrendingUp, 
  Users, 
  Trash2, 
  MessageSquare, 
  ThumbsUp, 
  ThumbsDown, 
  Lightbulb, 
  Smile, 
  Send,
  Twitter,
  Facebook,
  Linkedin,
  Link,
  Check
} from 'lucide-react';
import { 
  fetchGroupDetails, 
  deleteGroup, 
  joinGroup,
  fetchGroupComments,
  createGroupComment,
  reactToGroupComment,
  createGroupWebSocket
} from '../api';

const Card = ({ children, className = '' }) => (
  <div className={`bg-white rounded-lg shadow-sm ${className}`}>
    {children}
  </div>
);

const GroupDetailView = () => {
  const [group, setGroup] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isJoining, setIsJoining] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [isSubmittingComment, setIsSubmittingComment] = useState(false);
  const [showCopiedMessage, setShowCopiedMessage] = useState(false);
  const { groupId } = useParams();
  const navigate = useNavigate();

  // Fetch group details
  useEffect(() => {
    const loadGroupDetails = async () => {
      try {
        const data = await fetchGroupDetails(groupId);
        setGroup(data);
      } catch (err) {
        console.error('Error in loadGroupDetails:', err);
        const status = err.response?.status;

        if (status === 401) {
          localStorage.removeItem('accessToken');
          navigate('/login', { 
            replace: true, 
            state: { message: 'Session expired. Please log in again.' } 
          });
        } else {
          setError('Failed to load group details.');
        }
      } finally {
        setLoading(false);
      }
    };

    loadGroupDetails();
  }, [groupId, navigate]);

  // WebSocket connection for real-time comments
  // WebSocket connection for real-time comments
useEffect(() => {
  if (!group?.is_member) {
      console.log('Not connecting WebSocket - user is not a member');
      return;
  }

  console.log('Setting up WebSocket connection for group:', groupId);
  const ws = createGroupWebSocket(
      groupId,
      (data) => {
          console.log('Received WebSocket data:', data);
          if (data.type === 'reaction_update') {
              setComments(prev => prev.map(comment => 
                  comment.id === data.comment_id 
                      ? { ...comment, ...data.counts }
                      : comment
              ));
          } else if (data.type === 'comment_deleted') {
              setComments(prev => prev.filter(comment => comment.id !== data.comment_id));
          } else {
              // New comment
              setComments(prev => [data, ...prev]);
          }
      },
      (error) => console.error('WebSocket error:', error)
  );

  return () => {
      console.log('Cleaning up WebSocket connection');
      if (ws?.close) ws.close();
  };
}, [groupId, group?.is_member]);

  // Fetch comments
  useEffect(() => {
    if (!group?.is_member) return;

    const loadComments = async () => {
      try {
        const commentsData = await fetchGroupComments(groupId);
        setComments(commentsData);
      } catch (error) {
        console.error('Failed to fetch comments:', error);
      }
    };

    loadComments();
  }, [groupId, group?.is_member]);

  const handleJoinGroup = async () => {
    try {
      setIsJoining(true);
      await joinGroup(groupId);
      const updatedGroup = await fetchGroupDetails(groupId);
      setGroup(updatedGroup);
      alert('Successfully joined the group!');
    } catch (err) {
      console.error('Error joining group:', err);
      const status = err.response?.status;

      if (status === 401) {
        localStorage.removeItem('accessToken');
        navigate('/login', { 
          replace: true, 
          state: { message: 'Session expired. Please log in again.' } 
        });
      } else {
        alert(err.response?.data?.detail || err.message || 'Failed to join group');
      }
    } finally {
      setIsJoining(false);
    }
  };

  const handleDeleteGroup = async () => {
    const confirmed = window.confirm(
      'Are you sure you want to delete this group? This action cannot be undone.'
    );
  
    if (!confirmed) return;
  
    try {
      setIsDeleting(true);
      await deleteGroup(groupId);
      navigate('/dashboard', {
        replace: true,
        state: {
          message: 'Group successfully deleted'
        },
      });
    } catch (error) {
      console.error('Error deleting group:', error);
      const status = error.response?.status;
  
      if (status === 401) {
        localStorage.removeItem('accessToken');
        navigate('/login', {
          replace: true,
          state: { message: 'Session expired. Please log in again.' },
        });
      } else if (status === 403) {
        alert('You are not authorized to delete this group.');
      } else if (status === 404) {
        alert('Group not found.');
      } else {
        alert(error.response?.data?.detail || 'Failed to delete group');
      }
    } finally {
      setIsDeleting(false);
    }
  };

  const handleCommentSubmit = async (e) => {
    e.preventDefault();
    if (!newComment.trim()) return;

    setIsSubmittingComment(true);
    try {
      const comment = await createGroupComment(groupId, newComment);
      setComments(prev => [comment, ...prev]);
      setNewComment('');
    } catch (error) {
      console.error('Failed to post comment:', error);
      alert('Failed to post comment');
    } finally {
      setIsSubmittingComment(false);
    }
  };

  const handleReaction = async (commentId, reactionType) => {
    try {
      await reactToGroupComment(groupId, commentId, reactionType);
      // The WebSocket connection will handle updating the UI
    } catch (error) {
      console.error('Failed to react to comment:', error);
      alert('Failed to react to comment');
    }
  };

  // Social sharing functionality
  const getShareableContent = () => {
    if (!group) return '';
    const stats = `${group.member_count || 0} members • ${avgWinRate}% avg win rate`;
    return `Check out ${group.name} on BetSwami! ${stats}`;
  };

  const handleShare = (platform) => {
    const text = encodeURIComponent(getShareableContent());
    const url = encodeURIComponent(window.location.href);
    
    const shareUrls = {
      twitter: `https://twitter.com/intent/tweet?text=${text}&url=${url}`,
      facebook: `https://www.facebook.com/sharer/sharer.php?u=${url}`,
      linkedin: `https://www.linkedin.com/sharing/share-offsite/?url=${url}`
    };

    if (platform === 'copy') {
      navigator.clipboard.writeText(`${getShareableContent()} ${window.location.href}`).then(() => {
        setShowCopiedMessage(true);
        setTimeout(() => setShowCopiedMessage(false), 2000);
      });
    } else {
      window.open(shareUrls[platform], '_blank', 'width=600,height=400');
    }
  };

  const SocialButton = ({ platform, icon: Icon, label }) => (
    <button
      onClick={() => handleShare(platform)}
      className="inline-flex items-center gap-1 p-2 text-gray-500 hover:text-gray-700 
                 hover:bg-gray-100 rounded-full transition-colors"
      title={label}
    >
      <Icon className="h-5 w-5" />
    </button>
  );

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    return new Intl.RelativeTimeFormat('en', { numeric: 'auto' }).format(
      Math.round((date - new Date()) / (1000 * 60 * 60 * 24)),
      'day'
    );
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500" />
      </div>
    );
  }

  if (error || !group) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-red-500">{error || 'No group data available'}</div>
      </div>
    );
  }

  const members = group.members || [];
  const totalPoints = members.reduce((sum, member) => sum + (member.total_points || 0), 0);
  const topPlayer = members.length > 0 
    ? members.reduce((prev, current) => 
        ((prev.total_points || 0) > (current.total_points || 0)) ? prev : current
    ).username
    : 'N/A';
  const avgWinRate = members.length > 0
    ? (members.reduce((sum, member) => sum + (member.win_rate || 0), 0) / members.length).toFixed(1)
    : '0';

  return (
    <div className="min-h-screen bg-gray-50 p-4 md:p-8">
      {/* Header with Navigation */}
      <div className="mb-8">
        <div className="flex justify-between items-center mb-6">
          <button
            onClick={() => navigate('/dashboard', { replace: true })}
            className="flex items-center text-gray-600 hover:text-gray-900"
          >
            <ArrowLeft className="h-5 w-5 mr-2" />
            Back to Groups
          </button>
          
          <div className="flex items-center gap-4">
            {/* Social Share Buttons */}
            <div className="flex items-center gap-2 border-r pr-4">
              <SocialButton 
                platform="twitter" 
                icon={Twitter} 
                label="Share on Twitter"
              />
              <SocialButton 
                platform="facebook" 
                icon={Facebook}
                label="Share on Facebook"
              />
              <SocialButton 
                platform="linkedin" 
                icon={Linkedin}
                label="Share on LinkedIn"
              />
              <button
                onClick={() => handleShare('copy')}
                className="inline-flex items-center gap-1 p-2 text-gray-500 hover:text-gray-700 
                          hover:bg-gray-100 rounded-full transition-colors"
                title={showCopiedMessage ? "Copied!" : "Copy Link"}
              >
                {showCopiedMessage ? (
                  <Check className="h-5 w-5 text-green-600" />
                ) : (
                  <Link className="h-5 w-5" />
                )}
              </button>
            </div>

            {!group.is_member && (
              <button
                onClick={handleJoinGroup}
                disabled={isJoining}
                className="px-4 py-2 rounded-lg bg-blue-600 text-white font-medium 
                          hover:bg-blue-700 transition-colors 
                          disabled:opacity-50 disabled:cursor-not-allowed
                          flex items-center"
              >
                {isJoining ? (
                  <>
                    <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white mr-2" />
                    Joining...
                  </>
                ) : (
                  'Join Group'
                )}
              </button>
            )}

            {group.is_swami && (
              <button
                onClick={handleDeleteGroup}
                disabled={isDeleting}
                className="px-4 py-2 rounded-lg bg-red-600 text-white font-medium 
                          hover:bg-red-700 transition-colors 
                          disabled:opacity-50 disabled:cursor-not-allowed
                          flex items-center"
              >
                {isDeleting ? (
                  <>
                    <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white mr-2" />
                    Deleting...
                  </>
                ) : (
                  <>
                    <Trash2 className="h-4 w-4 mr-2" />
                    Delete Group
                  </>
                )}
              </button>
            )}
          </div>
        </div>

        {/* Group Info Card */}
        <Card className="p-6">
          <div className="flex flex-col md:flex-row md:items-center md:justify-between">
            <div>
              <h1 className="text-3xl font-bold text-gray-900">{group.name}</h1>
              <p className="text-gray-500 mt-2">{group.description}</p>
              <div className="mt-2 flex items-center text-sm">
                <Users className="h-4 w-4 text-gray-400 mr-2" />
                <span className="text-gray-600">{group.member_count || 0} members</span>
                <span className="mx-2">•</span>
                <span className="text-gray-600">Led by {group.swami_name}</span>
              </div>
            </div>
            <div className="mt-4 md:mt-0">
              <span className="px-4 py-2 bg-purple-100 text-purple-800 rounded-full text-sm font-medium">
                ${group.price} / month
              </span>
            </div>
          </div>

          {/* Stats Grid */}
          <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mt-6">
            <div className="bg-gray-50 p-4 rounded-lg">
              <div className="flex items-center">
                <Users className="h-5 w-5 text-blue-500 mr-2" />
                <span className="text-gray-600">Members</span>
              </div>
              <p className="text-2xl font-bold mt-2">{group.member_count || 0}</p>
            </div>
            <div className="bg-gray-50 p-4 rounded-lg">
              <div className="flex items-center">
                <Trophy className="h-5 w-5 text-yellow-500 mr-2" />
                <span className="text-gray-600">Group Points</span>
              </div>
              <p className="text-2xl font-bold mt-2">{totalPoints.toLocaleString()}</p>
            </div>
            <div className="bg-gray-50 p-4 rounded-lg">
              <div className="flex items-center">
              <Award className="h-5 w-5 text-green-500 mr-2" />
                <span className="text-gray-600">Top Player</span>
              </div>
              <p className="text-2xl font-bold mt-2">{topPlayer}</p>
            </div>
            <div className="bg-gray-50 p-4 rounded-lg">
              <div className="flex items-center">
                <TrendingUp className="h-5 w-5 text-indigo-500 mr-2" />
                <span className="text-gray-600">Avg Win Rate</span>
              </div>
              <p className="text-2xl font-bold mt-2">{avgWinRate}%</p>
            </div>
          </div>
        </Card>
      </div>

      {/* Leaderboard */}
      <Card className="p-6 mb-8">
        <h2 className="text-xl font-bold mb-6">Group Leaderboard</h2>
        <div className="overflow-x-auto">
          <table className="min-w-full">
            <thead>
              <tr className="border-b">
                <th className="text-left py-3 px-4">Rank</th>
                <th className="text-left py-3 px-4">Player</th>
                <th className="text-right py-3 px-4">Points</th>
                <th className="text-right py-3 px-4">Win Rate</th>
                <th className="text-right py-3 px-4">Predictions</th>
                <th className="text-right py-3 px-4">Streak</th>
              </tr>
            </thead>
            <tbody>
              {[...members]
                .sort((a, b) => (b.total_points || 0) - (a.total_points || 0))
                .map((member, index) => (
                  <tr 
                    key={member.user_id}
                    className="border-b hover:bg-gray-50 transition-colors"
                  >
                    <td className="py-3 px-4">
                      {index + 1}
                      {index < 3 && (
                        <span className="ml-2">
                          {index === 0 && '🥇'}
                          {index === 1 && '🥈'}
                          {index === 2 && '🥉'}
                        </span>
                      )}
                    </td>
                    <td className="py-3 px-4">
                      <div className="flex items-center">
                        <div className="h-8 w-8 rounded-full bg-gray-200 flex items-center justify-center mr-3">
                          {member.username?.charAt(0).toUpperCase() || '?'}
                        </div>
                        {member.username || 'Unknown'}
                      </div>
                    </td>
                    <td className="text-right py-3 px-4 font-medium">
                      {(member.total_points || 0).toLocaleString()}
                    </td>
                    <td className="text-right py-3 px-4">
                      {(member.win_rate || 0).toFixed(1)}%
                    </td>
                    <td className="text-right py-3 px-4">
                      {member.total_predictions || 0}
                    </td>
                    <td className="text-right py-3 px-4">
                      {member.streak || 0} 🔥
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </Card>

      {/* Comments Section - Only show for group members */}
      {group.is_member && (
        <Card className="p-6">
          <div className="flex items-center justify-between mb-6">
            <h2 className="text-xl font-bold">Group Discussion</h2>
            <div className="text-sm text-gray-500">
              {comments.length} {comments.length === 1 ? 'comment' : 'comments'}
            </div>
          </div>

          {/* Comment Input */}
          <form onSubmit={handleCommentSubmit} className="mb-6">
            <div className="flex gap-4">
              <div className="flex-shrink-0">
                <div className="h-10 w-10 rounded-full bg-gray-200 flex items-center justify-center">
                  {group.username?.charAt(0).toUpperCase()}
                </div>
              </div>
              <div className="flex-grow">
                <input
                  type="text"
                  value={newComment}
                  onChange={(e) => setNewComment(e.target.value)}
                  placeholder="Add to the discussion..."
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  disabled={isSubmittingComment}
                />
              </div>
              <button
                type="submit"
                disabled={isSubmittingComment || !newComment.trim()}
                className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 
                         disabled:opacity-50 disabled:cursor-not-allowed flex items-center gap-2"
              >
                {isSubmittingComment ? (
                  <div className="animate-spin rounded-full h-4 w-4 border-2 border-white" />
                ) : (
                  <Send className="h-4 w-4" />
                )}
                Post
              </button>
            </div>
          </form>

          {/* Comments List */}
          <div className="space-y-4">
            {comments.map((comment) => (
              <div key={comment.id} className="flex gap-4">
                <div className="flex-shrink-0">
                  <div className="h-10 w-10 rounded-full bg-gray-200 flex items-center justify-center">
                    {comment.username?.charAt(0).toUpperCase()}
                  </div>
                </div>
                <div className="flex-grow">
                  <div className="bg-gray-50 rounded-lg p-4">
                    <div className="flex items-center gap-2 mb-2">
                      <span className="font-medium">{comment.username}</span>
                      <span className="text-sm text-gray-500">
                        {formatTimestamp(comment.created_at)}
                      </span>
                    </div>
                    
                    {/* Show special styling for prediction announcements */}
                    {comment.type === 'prediction' ? (
                      <div className="bg-blue-50 p-3 rounded-md">
                        <span className="font-medium text-blue-800">New Prediction!</span>
                        <p className="text-blue-700">{comment.content}</p>
                      </div>
                    ) : (
                      <p className="text-gray-700">{comment.content}</p>
                    )}

                    {/* Reaction Buttons */}
                    <div className="flex gap-4 mt-3">
                      <button
                        onClick={() => handleReaction(comment.id, 'LIKE')}
                        className={`flex items-center gap-1 ${
                          comment.user_reaction === 'LIKE' 
                            ? 'text-blue-500' 
                            : 'text-gray-500 hover:text-blue-500'
                        }`}
                      >
                        <ThumbsUp className="h-4 w-4" />
                        <span>{comment.likes || 0}</span>
                      </button>
                      <button
                        onClick={() => handleReaction(comment.id, 'DISLIKE')}
                        className={`flex items-center gap-1 ${
                          comment.user_reaction === 'DISLIKE' 
                            ? 'text-red-500' 
                            : 'text-gray-500 hover:text-red-500'
                        }`}
                      >
                        <ThumbsDown className="h-4 w-4" />
                        <span>{comment.dislikes || 0}</span>
                      </button>
                      <button
                        onClick={() => handleReaction(comment.id, 'INSIGHTFUL')}
                        className={`flex items-center gap-1 ${
                          comment.user_reaction === 'INSIGHTFUL' 
                            ? 'text-purple-500' 
                            : 'text-gray-500 hover:text-purple-500'
                        }`}
                      >
                        <Lightbulb className="h-4 w-4" />
                        <span>{comment.insightful || 0}</span>
                      </button>
                      <button
                        onClick={() => handleReaction(comment.id, 'FUNNY')}
                        className={`flex items-center gap-1 ${
                          comment.user_reaction === 'FUNNY' 
                            ? 'text-yellow-500' 
                            : 'text-gray-500 hover:text-yellow-500'
                        }`}
                      >
                        <Smile className="h-4 w-4" />
                        <span>{comment.funny || 0}</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Card>
      )}
    </div>
  );
};

export default GroupDetailView;