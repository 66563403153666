import React from 'react';
import { Dialog, DialogContent } from './ui/dialog';
import WithdrawalForm from './WithdrawalForm';

const WithdrawalModal = ({ open, onClose }) => {
  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px]">
        <WithdrawalForm 
          onSuccess={() => {
            onClose();
          }} 
        />
      </DialogContent>
    </Dialog>
  );
};

export default WithdrawalModal;